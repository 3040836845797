import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import MeterTrackings from "./components/meterTracking.js"



function MeterTracking() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
     <MeterTrackings/>
    </div>
  );
}
export default MeterTracking;
