import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/w.gif';

function SystemInfo() {
  return (
    <div>
      <div className="card card-outline card-secondary mx-auto">
        <div className="col-12 text-center">
          <h1 className="h3 text-primary pt-3">Integrated Leakage Management System</h1>
        </div>
      </div>
      <div className="card col-6 mx-auto bg-light">
        <div className="col-6 mx-auto text-center">
          <img src={logo}  className="img-responsive mx-auto col-6" alt="logo" />
        </div>
        <div className="row p-4">
          <div className="col-lg-6">
            <div className="bg-primary m-2 card small-box text-center text-white">
              <div className="inner">
                <h3><strong>System</strong></h3>
                <p>Login for system settings</p>
              </div>
              <div className="icon">
                <i className="fas fa-book-reader"></i>
              </div>
              <Link to="/admins/system-login" className="small-box-footer text-white text-decoration-none">
                Click Here <i className="fas fa-arrow-circle-right"></i>
              </Link>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="bg-danger m-2 card small-box text-center text-white">
              <div className="inner">
                <h3><strong>ADMINS</strong></h3>
                <p>Click for Admin to Login</p>
              </div>
              <div className="icon">
                <i className="fas fa-book-reader"></i>
              </div>

              <Link to="/admins/login" className="small-box-footer text-white text-decoration-none">
                Click Here <i className="fas fa-arrow-circle-right"></i>
              </Link>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="bg-warning m-2 card small-box text-center text-white ">
              <div className="inner">
                <h3><strong>TECHNICIAN</strong></h3>
                <p>Click for technician to Login</p>
              </div>
              <div className="icon">
                <i className="fas fa-book-reader"></i>
              </div>
              <Link to="/admins/technician-login" className="small-box-footer text-white text-decoration-none">
                Click Here <i className="fas fa-arrow-circle-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bg-success m-2 card small-box text-center text-white">
              <div className="inner">
                <h3><strong>MOBILE APP</strong></h3>
                <p>Click to download mobile App</p>
              </div>
              <div className="icon">
                <i className="fas fa-book-reader"></i>
              </div>
              <a href="#" className="small-box-footer text-white text-decoration-none">
                Click Here <i className="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemInfo;
