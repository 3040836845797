import React from "react";
import TechnicianHeader from "./components/technicianHeader";
import TechnicianSummary from "./components/technicianSummary";
import LeakageTechnicians from "./components/GetLeakageTechnician"



function GetLeakageTechnician() {
  return (
    <div className="home">
      <TechnicianHeader />
      <TechnicianSummary />
      <LeakageTechnicians />

    </div>
  );
}
export default GetLeakageTechnician;
