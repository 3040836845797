import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import ZonalData from "./components/zonal.js";


function Zonal() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <ZonalData/>
      
    </div>
  );
}
export default Zonal;
