import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import UnclearedStatus from './unclearedLeakage';
import ClearedStatus from './clearedLeakage';
import OnProgressStatus from './OnProgressLeakage';

function LeakageTechnicians() {
  const [data, setData] = useState(null);
  const [zonal, setZonal] = useState('');
  const [zonalName, setZonalName] = useState('');
  const [agencyCode, setAgencyCode] = useState('');
  const token = localStorage.getItem("token");
  const { zone, leakage_id } = useParams();

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setZonal(user.zonal_id);
      setZonalName(user.zonal_name);
      setAgencyCode(user.agency_code);
      fetchData(user.zonal_id, user.agency_code, token);
    }
  }, [token]);

  const fetchData = async (zonal, agencyCode, token) => {
    try {
      if (!agencyCode || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get("https://api.ilms.co.tz/api/get_leakage_by_technician", {
        params: {
          agency_code: agencyCode,
          token: token,
          zone: zone,
          leakage_id: leakage_id
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const fetchedData = response.data;
      console.log("Received Fault Status:", fetchedData.fault_status); // Log the fault status
      setData(fetchedData);

      console.log("Customer Data:", fetchedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData(null);
    }
  };

  const getStatusDetails = (status) => {
    switch (status) {
      case '0':
      case 0:
        return { className: "bg-danger", statusText: "Uncleared" };
      case '1':
      case 1:
        return { className: "bg-success", statusText: "Cleared" };
      case '2':
      case 2:
        return { className: "bg-warning", statusText: "On Progress" };
      default:
        return { className: "bg-info", statusText: "Unknown" };
    }
  };

  const renderStatusContent = (status, data) => {
    const numericStatus = Number(status); // Convert status to a number

    if (numericStatus === 0) {
      return <UnclearedStatus data={data} onSubmit={handleFormSubmit} onSuccess={() => fetchData(zonal, agencyCode, token)} />;
    } else if (numericStatus === 1) {
      return <ClearedStatus data={data} />;
    } else if (numericStatus === 2) {
      return <OnProgressStatus data={data} onSuccess={() => fetchData(zonal, agencyCode, token)} />;
    } else {
      return (
        <div className="bg-info">
          <center>Unknown Fault Status</center>
        </div>
      );
    }
  };

  const handleFormSubmit = (formData) => {
    return axios.post('https://api.ilms.co.tz/api/uncleared-leakage', formData)
      .then(response => {
        console.log('Form submitted successfully:', response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully',
        });
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit the form',
        });
        throw error;
      });
  };

  return (
    <div className="col-lg-8 mx-auto col-11">
      <div className="col-lg-12 mt-3 mb-3 p-2 card">
        {!data ? (
          <div className="col-12 text-center">
            <p className="text-danger h4">No data found</p>
          </div>
        ) : (
          <>
            <div className={`small-box ${getStatusDetails(data.fault_status).className}`}>
              <p className="text-center">
                <strong>Reported Date:</strong> {new Date(data.created_at).toLocaleDateString()} &nbsp;&nbsp;
                <strong>Status:</strong> {getStatusDetails(data.fault_status).statusText}
              </p>
            </div>
            <h5 className="pt-2">Faults Details</h5>
            <p><strong>Reporter Phone Number:</strong> {data.phone_number}</p>
            <p><strong>District:</strong> {data.district_name}</p>
            <p><strong>Ward:</strong> {data.ward_name}</p>
            <p><strong>Street:</strong> {data.street_name}</p>
            <p><strong>Location:</strong><a href={`https://www.google.com/maps/place/${encodeURIComponent(data.location)}`} target="_blank"> {data.location}</a></p>
            <div className="col-12 bg-light p-2">
              <p className="border-bottom h5">Description</p>
              <p>{data.description}</p>
            </div>
            {renderStatusContent(data.fault_status, data)}
          </>
        )}
      </div>
    </div>
  );
}

export default LeakageTechnicians;
