import React from 'react';

const TechLogout = () => {
  const handleLogout = () => {
    // Set 'auth' to false in localStorage
    localStorage.setItem('auth', 'false');

    // Clear the entire localStorage
    localStorage.clear();
    
    // Redirect the user to the login page
    window.location.href = '/admins/technician-login';
  };

  // Call handleLogout when the component mounts
  React.useEffect(() => {
    handleLogout();
  }, []);

  // You can render a message or a loading spinner while the logout process completes
  return (
    <div>Logging out...</div>
  );
};

export default TechLogout;
