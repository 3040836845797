import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import DataCollector from "./components/dataCollectors.js"



function DataCollectors() {
  return (
    <div className="home">
      <Header />
      <SideNav />
     <DataCollector/>
    </div>
  );
}
export default DataCollectors;
