import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import qs from 'qs';
import Swal from 'sweetalert2';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const ClearedStatus = ({ data, onSuccess }) => {
  const [show, setShow] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [photo, setPhoto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [useCamera, setUseCamera] = useState(false);
  const webcamRef = useRef(null);
  const userString = localStorage.getItem("user");
  const user = JSON.parse(userString);
  const token = localStorage.getItem("token");
  const { zone, leakage_id } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCommentChange = (e) => setNewComment(e.target.value);

  function formatDate(dateStr) {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: false
    };
    
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`https://api.ilms.co.tz/api/load-leakage-comments`, {
          params: { leakage_id: leakage_id, token: token, agency_code: user.agency_code }
        });
        setComments(response.data);
      } catch (error) {
        console.error('Error loading comments:', error);
      }
    };

    fetchComments();
  }, [leakage_id, token, user.agency_code]);

  const handleAddComment = async () => {
    try {
      const response = await axios.post(
        'https://api.ilms.co.tz/api/add-leakage-comment',
        qs.stringify({
          agency_code: user.agency_code,
          leakage_id: leakage_id,
          user_name: user.full_name,
          comment: newComment,
          token: token,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      // Add the new comment to the state
      setComments([...comments, response.data]);
      setNewComment('');
      handleClose();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURItoBlob(imageSrc);
    setPhoto(blob);
    setPreviewUrl(imageSrc);
  }, [webcamRef]);

  const handleSubmitUncleared = async (e) => {
    e.preventDefault();
    if (!comment || !photo) {
      setError('Both comment and photo are required.');
      return;
    }
    setError('');
    setSubmitting(true);
    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('photo', photo);
    formData.append('user_name', user.full_name);
    formData.append('leakage_id', leakage_id);
    formData.append('token', token);
    formData.append('technician_id', user.id);
    try {
      await axios.post('https://api.ilms.co.tz/api/cleared-leakage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // Handle success, show success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Form submitted successfully',
      }).then(() => {
        onSuccess(); // Call the onSuccess function passed from the parent component
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit the form',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <>
      <div className="bg-info">
        <div className='row'>
          <div className='col-md-5'>
            <img src={`/uploads/${data.before_photo}`} className='img-fluid' alt="Site Image" />
          </div>
          <div className='col-md-7'>
            <p className="border-bottom h5 p-2">Site Attended by: {data.start_technician_name}</p>
            <p className='h6 pl-2'><strong>Comment:</strong></p>
            <p className='pl-2'>{data.start_comments}</p>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <h5>Comments:</h5>
        {comments.length === 0 ? (
          <p>No comments found.</p>
        ) : (
          comments.map((comment, index) => (
            <div key={index} className="mb-2">
              <div className='card bg-light p-2'>
                <div className='row'>
                  <div className='col-lg-1 col-3'>
                    <i className='fa fa-user fa-4x'></i>
                  </div>
                  <div className='col-lg-11 col-9'>
                    <p className='border-bottom'><strong>{comment.commenter_name} </strong>&nbsp;&nbsp; <i className='text-secondary'>{formatDate(comment.created_at)}</i></p>
                    <p>{comment.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <Button variant="primary" onClick={handleShow} className='ml-2'>
        Add Comment
      </Button>
      <div className='mb-4'></div>
      <div className="bg-light">
        <div className='row'>
          <div className='col-md-5'>
            <img src={`/uploads/${data.after_photo}`} className='img-fluid' alt="Site Image" />
          </div>
          <div className='col-md-7'>
            <p className="border-bottom h5 p-2">Leakage Cleared By: {data.cleared_technician_name}</p>
            <p className='h6 pl-2'><strong>Clearance Report:</strong></p>
            <p className='pl-2'>{data.comments}</p>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newComment}
                onChange={handleCommentChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddComment}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClearedStatus;
