import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterSource from "./components/waterSources.js"
import Footer from "./components/footer.js";

function WaterSources() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterSource/>
      <Footer/>
    </div>
  );
}
export default WaterSources;
