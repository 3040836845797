import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterLevels from "./components/waterLevel.js"


function TanksLevel() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
     <WaterLevels/>
    </div>
  );
}
export default TanksLevel;
