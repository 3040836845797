import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import ViewCustomerDetails from "./components/viewCustomer.js"



function ViewCustomer() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
     <ViewCustomerDetails/>
    </div>
  );
}
export default ViewCustomer;
