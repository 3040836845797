import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import TankLocations from "./components/tankLocations.js"

function TankLocation() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <TankLocations />
      <Footer />
    </div>
  );
}
export default TankLocation;