import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import SystemAdmin from "./components/systemAdmins.js"



function SystemAdmins() {
  return (
    <div className="home">
      <Header />
      <SideNav />
     <SystemAdmin/>
    </div>
  );
}
export default SystemAdmins;
