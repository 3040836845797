import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./w.gif"



function SideNav() {
  return (
    <div>
    {/* Main Sidebar Container */}
<aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  <Link href="home" className="brand-link">
    <img src={logo} className="brand-image img-circle elevation-3" style={{height: 100, width: 50}} />
    <span className="brand-text font-weight-bold h3 text-primary"><b>ILMS</b></span>
  </Link>
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
        {/* TOP MENU*/}
        <li className="nav-item has-treeview">
          <Link to="/admins/system-home" className="nav-link">
            <p>
              Home
              <i className="right fas fa-angle-left" />
            </p>
          </Link>
          </li>
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <p>
              Registered Agencies
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <Link to="/admins/customer-details" className="nav-link">
                <i className="fa fa-users" aria-hidden="true" />
                <p className="small"> Customers Details</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/customer-locations" className="nav-link">
                <i className="fa fa-globe" aria-hidden="true" />
                <p className="small"> Customers Locations</p>
              </Link>
            </li>   
            <li className="nav-item">
              <Link to="/admins/data-collectors-data" className="nav-link">
                <i className="fa fa-user" aria-hidden="true" />
                <p className="small"> Data Collectors Data</p>
              </Link>
            </li>  
          </ul>
        </li>
        {/* TOP MENU*/}
       
        {/* slider links*/}
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <p>
              Customer Settings
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <Link to="/admins/water-tanks" className="nav-link">
                <i className="fa fa-file" aria-hidden="true" />
                <p className="small"> Water Tanks</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/water-sources" className="nav-link">
                <i className="fa fa-file" aria-hidden="true" />
                <p className="small"> Water Sources</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/water-boreholes" className="nav-link">
                <i className="fa fa-file" aria-hidden="true" />
                <p className="small"> Boreholes</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/water-appurtenances" className="nav-link">
                <i className="fa fa-file" aria-hidden="true" />
                <p className="small"> Water Appurtenances</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/water-network" className="nav-link">
                <i className="fa fa-file" aria-hidden="true" />
                <p className="small"> Water network</p>
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <p>
              Infrastructure Settings
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <Link to="/admins/GIS-Maps" className="nav-link">
                <i className="fa fa-users" aria-hidden="true" />
                <p className="small"> View GIS Map</p>
              </Link>
            </li>
            
          </ul>
        </li>
        {/* special offer */}
        {/* water meter replacement */}
        {/* slider links*/}
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <p>
              Water Quality Settings
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
          <li className="nav-item">
              <Link to="/admins/meters-replacement-overview" className="nav-link">
                <i className="fa fa-plus-square" aria-hidden="true" />
                <p className="small"> Meter Replacement Overview</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/meters-tracking" className="nav-link">
                <i className="fa fa-plus-square" aria-hidden="true" />
                <p className="small"> Tracking Meter</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/repaced-meters" className="nav-link">
                <i className="fa fa-plus-square" aria-hidden="true" />
                <p className="small"> Replaced Meters</p>
              </Link>
            </li>
          </ul>
        </li>
        {/* slider links*/}
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <p>
              Other Settings
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
          <li className="nav-item">
              <Link to="/admins/report-leakage" className="nav-link">
                <i className="fa fa-info" aria-hidden="true" />
                <p className="small"> &nbsp;Report Leakage</p>
              </Link>
            </li>
          <li className="nav-item">
              <Link to="/admins/track-technicians" className="nav-link">
                <i className="fa fa-info" aria-hidden="true" />
                <p className="small"> &nbsp;Track Technicians</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/faults-summary" className="nav-link">
                <i className="fa fa-info" aria-hidden="true" />
                <p className="small"> &nbsp; Reported Faults</p>
              </Link>
            </li>
            
            
          </ul>
        </li>
        {/* slider links*/}
       
        {/* Setting start here*/}
        <li className="nav-item has-treeview">
          <a href="alert.php" className="nav-link">
            <p>
              System Users
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <Link to="/admins/system-admins" className="nav-link">
                <i className="fas fa-certificate" />
                <p className="small"> System Admins</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/data-collectors" className="nav-link">
                <i className="fas fa-certificate" />
                <p className="small"> Data Collectors</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admins/technicians" className="nav-link">
                <i className="fas fa-certificate" />
                <p className="small"> Technician</p>
              </Link>
            </li>
            
          </ul>
        </li>
        {/* Setting start here*/}
       
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>

    </div>
  );
}

export default  SideNav;