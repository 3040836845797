import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';
import { Chart as GoogleChart } from 'react-google-charts';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function TrackingTech() {
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(0);
  const [techniciansList, setTechniciansList] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTechnicians = () => {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      axios.get('https://api.ilms.co.tz/api/technicians-list', {
        params: {
          agency_code: agency_code,
          token: token
        },
      }) 
        .then(response => {
          setTechniciansList(response.data.data.map(technician => ({
            value: technician.id,
            label: technician.full_name // use full_name based on the response data
          })));
        })
        .catch(error => {
          console.error('Error fetching technicians:', error);
        });
    };

    const fetchData = () => {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }
  
      setProgress(50);
  
      axios.get(`https://api.ilms.co.tz/api/get-leakage-summary`, {
        params: {
          agency_code: agency_code,
          token: token
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        setData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setProgress(100);
        setTimeout(() => setProgress(0), 3000); // 3 seconds instead of 5 minutes
      });
    };

    fetchTechnicians();
    // Fetch data initially
    fetchData();
  
    // Fetch data every 10 minutes
    const interval = setInterval(fetchData, 600000);
  
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [token]);

  const handleSearch = () => {
    if (fromDate && toDate && selectedTechnician) {
      navigate(`/admins/fault-find-technician/${selectedTechnician.value}/${fromDate}/${toDate}`);
    } else {
      Swal.fire("Please select all fields", "", "warning");
    }
  };

  const zonalFaultData = [
    ['Zone', 'Number of Faults'],
    ...data.zonal_fault_counts?.map(item => [item.zonal_name, item.fault_count]) || []
  ];

  const serviceTypeFaultData = [
    ['Service Type', 'Number of Faults'],
    ...data.service_type_fault_counts?.map(item => [item.service_type_name, item.fault_count]) || []
  ];

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
      />
      <div className="content-wrapper">
        <p className="h4 text-primary pt-4 text-center">Track the Technician</p>
        <div className="mb-4"></div>
        <div className="row mx-auto">
          <div className="col-md-6">
            <div className="card mt-6">
              <div className="card-header">Faults by Zone</div>
              <div className="card-body">
                {zonalFaultData.length > 1 ? (
                  <GoogleChart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={zonalFaultData}
                    options={{
                      title: 'Faults by Zone',
                      is3D: true,
                    }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mt-6">
              <div className="card-header">Faults by Service Type</div>
              <div className="card-body">
                {serviceTypeFaultData.length > 1 ? (
                  <GoogleChart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={serviceTypeFaultData}
                    options={{
                      title: 'Faults by Service Type',
                      is3D: true,
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card">
              <div className="card-header">Find Technician Record</div>
              <div className="card-body">
                <form className="form-inline my-2 my-lg-0">
                  <Select
                    onChange={setSelectedTechnician}
                    placeholder="Select Technician"
                    options={techniciansList}
                    styles={{ container: (base) => ({ ...base, width: 250 }) }}
                  />
                  &nbsp; From &nbsp;
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="from_date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  to &nbsp;
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="to_date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-primary my-2 my-sm-0"
                    type="button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingTech;
