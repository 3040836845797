import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Button, Modal, Form } from "react-bootstrap";
import Swal from 'sweetalert2';

function WaterMeasurementCategories() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const userString = localStorage.getItem('user');
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setProgress(50);
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get(`https://api.ilms.co.tz/api/listed_WQ_categories`, {
        params: {
          agency_code: agency_code,
          token: token,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data && Array.isArray(response.data)) {
        console.log(response.data);

        setData(response.data);
      } else {
        setData([]);
      }

      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  const addNew = () => {
    setIsEditing(false);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewCategoryName("");
  };

  const handleEdit = (category) => {
    setIsEditing(true);
    setEditId(category.id);
    setNewCategoryName(category.name);
    setShowModal(true);
  };

  const handleNewCategorySubmit = async () => {
    try {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!newCategoryName) {
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });
        return;
      }

      if (isEditing) {
        // Edit existing category
        await axios.put(`https://api.ilms.co.tz/api/edit_measurement_category/${editId}`, {
          name: newCategoryName,
          token, 
          agency_code,
          editId,
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Category updated successfully!',
        });
      } else {
        // Add new category
        await axios.post(`https://api.ilms.co.tz/api/add_measurement_category`, {
          name: newCategoryName,
          token, 
          agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Category added successfully!',
        });
      }

      setShowModal(false);
      setNewCategoryName("");
      // Refetch categories after update
      fetchCategories();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Sorry! an error occurred',
      });
    }
  };

  const handleDeleteData = (item) => {
    // Display confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this Detail. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion operation with the categoryId
        axios.delete('https://api.ilms.co.tz/api/delete_measurement_category', { data: { item: item, token: token } })
          .then(response => {
            // Handle success
            // Show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            // Update UI by removing the deleted category from data state
            fetchCategories(); // Refresh categories after deletion
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting category:', error);
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the category.',
              icon: 'error'
            });
          });
      }
    });
  };

  const handleSearch = async () => {
    try {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      const search_type = document.getElementById('search_type').value.trim();

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      if (!search_type) {
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });
        return;
      }

      setProgress(50);

      const response = await axios.get(`https://api.ilms.co.tz/api/search_category`, {
        params: {
          agency_code: agency_code,
          token: token,
          search_type: search_type
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.length === 0) {
        setData([]);
        Swal.fire({
          icon: 'info',
          title: 'Not Found',
          text: 'No categories found',
        });
      } else {
        setData(response.data);
      }

      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-9">
                <p className="h3 text-primary">Water Quality Measurement Categories</p>
              </div>
             
              <div className="col-lg-3">
                <Button className="btn btn-md btn-success" onClick={addNew} disabled={!isAdmin}>
                  <span className="fa fa-plus" ></span> Add new Record
                </Button>
              </div>
            </div>
          </div>
          {data.length === 0 ? (
            <center>
              <p className="h4">No categories registered</p>
            </center>
          ) : (
            <div className="col-lg-10 mb-2">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((record, index) => (
                    <tr key={index}>
                      <td>{record.name}</td>
                      <td>
                        {/* Edit button */}
                        <span
                          className="fa fa-edit text-primary"
                          onClick={() => {
                            if (isAdmin) {
                              handleEdit(record);
                            }
                          }}
                          style={{ cursor: isAdmin ? 'pointer' : 'not-allowed', opacity: isAdmin ? 1 : 0.5 }}
                        ></span>
                        
                        &nbsp;&nbsp;

                        {/* Delete button */}
                        <span
                          className="fa fa-trash text-danger"
                          onClick={() => {
                            if (isAdmin) {
                              handleDeleteData(record.id);
                            }
                          }}
                          style={{ cursor: isAdmin ? 'pointer' : 'not-allowed', opacity: isAdmin ? 1 : 0.5 }}
                        ></span>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Modal for adding new category */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Category" : "Add New Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                id="newCategoryName"
                placeholder="Enter category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewCategorySubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default WaterMeasurementCategories;
