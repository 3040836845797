import React from "react";
import TechnicianHeader from "./components/technicianHeader";
import TechnicianSummary from "./components/technicianSummary";
import LeakageZoneListing from "./components/leakageZoneListing"


function TechnicianHomes() {
  return (
    <div className="home">
      <TechnicianHeader />
      <TechnicianSummary />
      <LeakageZoneListing />
    </div>
  );
}
export default TechnicianHomes;
