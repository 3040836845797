import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import { format } from 'date-fns'

function MeterReplacementHistories() {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [replacementHistory, setReplacementHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { account } = useParams();
  const userString = localStorage.getItem('user');
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  useEffect(() => {
    fetchCustomerDetails();
    fetchReplacementHistory();
  }, [account]); // Dependency array to re-fetch data when 'account' changes

  const fetchCustomerDetails = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get("https://api.ilms.co.tz/api/account_meter_history", {
        params: {
          agency_code: agency_code,
          token: token,
          account: account, // Pass the account parameter to the backend
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.data) {
        setCustomerDetails(response.data.data[0]);
      } else {
        setCustomerDetails(null);
      }

      setLoading(false);
      setProgress(100);
    } catch (error) {
      console.error("Error fetching customer details: ", error);
      setLoading(false);
      setProgress(0);
    }
  };

  const fetchReplacementHistory = async () => {
    try {
     
      const token = localStorage.getItem("token");
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

     

      const response = await axios.get("https://api.ilms.co.tz/api/account_meter_replacements", {
        params: {
          agency_code: agency_code,
          token: token,
          account: account, // Pass the account parameter to the backend
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const fetchedReplacementHistory = response.data.data || [];
      setReplacementHistory(fetchedReplacementHistory);

      
    } catch (error) {
      console.error("Error fetching replacement history: ", error);
      
    }
  };
  const formatDate = (date) => {
    return format(new Date(date), "dd/MMM/yyyy");
  };
  
  const handleDelete  = (id) => {
    const token = localStorage.getItem('token');
    // Display confirmation dialog
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this Detail. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion operation with the customerId
        axios.delete('https://api.ilms.co.tz/api/delete-meter-record', { data: { id: id, token:token } })
          .then(response => {
            // Handle success
            // Show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            fetchReplacementHistory();
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting customer:', error);
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the customer.',
              icon: 'error'
            });
          });
      }
    });
  };
  return (
    <div className="content-wrapper">
      <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />
      <div className="col-12 mx-auto">
        {customerDetails ? (
          <div className="card bg-light mt-4">
            <p className="p-4">
              Name of the client: <strong className="text-success">{`${customerDetails.first_name} ${customerDetails.middle_name} ${customerDetails.sur_name}`}</strong><br/>
              Phone Number: <strong className="text-success">{customerDetails.phone_number}</strong><br/>
              Account Number: <strong className="text-success">{account}</strong><br/>
              Customer Location: <strong className="text-success"> <a className="text-success" href={`https://www.google.com/maps/place/${customerDetails.location}`} target="_blank">{customerDetails.location}</a></strong><br/>
              Current Meter Number: <strong className="text-success">{customerDetails.meter_number}</strong><br/>
            </p>
          </div>
        ) : (
          <center className="text-danger">Account not found</center>
        )}
        <div className="mb-4"></div>
        <p className="text-center text-success h4">Meter Replacement History</p>

        {loading ? (
          <center>Loading...</center>
        ) : (
          <div>
            {replacementHistory.length === 0 ? (
              <center className="text-danger">No record found</center>
            ) : (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Installed Meter</th>
                    <th>Replaced Meter</th>
                    <th>Condition</th>
                    <th>Meter Type</th>
                    <th>Meter Brand</th>
                    <th>Location</th>
                    <th>Reason</th>
                    <th>Meter Taken</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {replacementHistory.map((item, index) => (
                    <tr key={index}>
                      <td className="text-success">{item.new_meter}</td>
                      <td className="text-danger" >{item.old_meter}</td>
                      <td>{item.meter_condition_name}</td>
                      <td>{item.meter_type_name}</td>
                      <td>{item.meter_brand_name}</td>
                      <td><a className="text-primary" href={`https://www.google.com/maps/place/${item.location}`}>Click</a></td>
                      <td >{item.reason_name	}</td>
                      <td >{item.meter_taken_name	}</td>
                      <td >{formatDate(item.created_date)}</td>
                      <td className="text-danger"><span
                    className="fa fa-trash"
                    onClick={() => {
                      if (isAdmin) {
                        handleDelete(item.id);
                      }
                    }}
                    style={{ cursor: isAdmin ? 'pointer' : 'not-allowed', opacity: isAdmin ? 1 : 0.5 }} // Change cursor and opacity for visual feedback
                  ></span>
                  </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MeterReplacementHistories;
