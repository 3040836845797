import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from 'react-top-loading-bar';

function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);

  const userId = JSON.parse(localStorage.getItem('user')).id; // Get user id from localStorage
  const token = localStorage.getItem('token'); // Get JWT token from localStorage

  const handleChangePassword = async () => {
    setError('');
    setSuccess(false);

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    setProgress(50); // Start the loading bar

    try {
      const response = await axios.put(`https://api.ilms.co.tz/api/password_change`, {
        newPassword,
        userId,
        token,
      });

      if (response.status === 200) {
        setSuccess(true);
        // Display success alert
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Password changed successfully!',
        });
      } else {
        setError('Failed to change password');
        // Display error alert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to change password',
        });
      }
    } catch (error) {
      setError('An error occurred while changing password');
      // Display error alert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while changing password',
      });
    }  finally {
        setProgress(100); // Complete the loading bar
        setTimeout(() => setProgress(0), 500); // Reset progress after a delay
      }
    };

  const removePassError = () => {
    setError('');
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
      />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-8">
                <h1 className="m-0">Change password</h1>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="col-sm-5">
              <div className="form-group">
                <label htmlFor="pass1">Enter new Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="pass1"
                  name="pass1"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onKeyUp={removePassError}
                  placeholder="Password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="pass2">Re-enter Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="pass2"
                  name="pass2"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyUp={removePassError}
                  placeholder="Password"
                />
                <input type="hidden" id="user_id_no" defaultValue={userId} />
              </div>
              <div id="change_pass_error" className="form-group">{error && <p style={{ color: 'red' }}>{error}</p>}</div>
              <div className="modal-footer">
                <button
                  id="change_pass_button"
                  type="button"
                  className="btn btn-success"
                  onClick={handleChangePassword}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ChangePassword;
