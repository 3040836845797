import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

//note this code reuse to edit the Boreholes
//replace the tank declaration with the Boreholes
function EditBoreholes() {
    const { borehole_id } = useParams();
    const [progress, setProgress] = useState(0);
    const [zonalOptions, setZonalOptions] = useState([]);
    const [selectedZonal, setSelectedZonal] = useState('');
    const [wards, setWards] = useState([]);
    const [selectedWardId, setSelectedWardId] = useState('');
    const [streets, setStreets] = useState([]);
    const [selectedStreetId, setSelectedStreetId] = useState('');
    const [WaterSourceDetails, setWaterSourceDetails] = useState({});
    const [boreholeStatuses, setboreholeStatus] = useState([]);
    const [selectedBoreholeStatus, setSelectedboreholeStatus] = useState('');
    const [noData, setNoData] = useState(false);
    const userString = localStorage.getItem("user");
    let isAdmin = false;
  
  if (userString) {
    const user = JSON.parse(userString);
    isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

    useEffect(() => {
        const fetchZonalData = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_zones', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setZonalOptions(response.data);
            } catch (error) {
                console.error('Error fetching zonal data: ', error);
            }
        };

        fetchZonalData();
    }, []);

   

    useEffect(() => {
        const fetchnoreholeStatuses = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_borehole_status', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const boreholeStatus = response.data;
                setboreholeStatus(boreholeStatus);
               
               
                setProgress(100);
            } catch (error) {
                console.error('Error fetching tank statuses data: ', error);
            }
        };

        fetchnoreholeStatuses();
    }, []);

    const getSelectedWards = async (event) => {
        const selectedZonalId = event.target.value;
        setSelectedZonal(selectedZonalId);

        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_wards`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedZonalId: selectedZonalId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setWards(response.data);
        } catch (error) {
            console.error('Error fetching wards data: ', error);
        }
    };

    const fetchStreets = async (event) => {
        const selectedWardId = event.target.value; // Use selected ward ID
        setSelectedWardId(selectedWardId);
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_streets`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedWardId: selectedWardId // Pass selected ward ID
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setStreets(response.data); // Update streets state
        } catch (error) {
            console.error('Error fetching streets data: ', error);
        }
    };

    useEffect(() => {
        setProgress(50);
        const fetchboreholeDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`https://api.ilms.co.tz/api/get_borehole_details/${borehole_id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const sourceData = response.data[0];

                // Update state with fetched tank details
                if (sourceData) {
                    setWaterSourceDetails(sourceData);
                   setSelectedZonal(sourceData.zone_id);
                   setSelectedboreholeStatus(sourceData.borehole_status_id	);
                } else {
                    setNoData(true);
                }
                
                setProgress(100);

            } catch (error) {
                setProgress(100);
                console.error('Error fetching tank details: ', error);
            }
        };
        fetchboreholeDetails();
    }, [borehole_id]);

    useEffect(() => {
        // Set the initial selected zonal value from tankDetails
        setSelectedZonal(WaterSourceDetails.zone_id);
    }, [WaterSourceDetails.zone_id]); // Trigger when tankDetails.zone_id changes

    useEffect(() => {
        setSelectedWardId(WaterSourceDetails.ward_id);
        getSelectedWards({ target: { value: WaterSourceDetails.zone_id} }); // Call getSelectedWards function with updated zonal value
    }, [WaterSourceDetails.ward_id, WaterSourceDetails.zone_id]); // Trigger when tankDetails.ward_id or tankDetails.zone_id changes

    useEffect(() => {
       
        setSelectedStreetId(WaterSourceDetails.street_id);
        fetchStreets({ target: { value: WaterSourceDetails.ward_id } }); // Call fetchStreets function with updated ward value
    }, [WaterSourceDetails.street_id, WaterSourceDetails.ward_id]); // Trigger when tankDetails.street_id or tankDetails.ward_id changes


    const updateWaterSourceDetails = async () => {
      try {
        // Gather all the field values
        const borehole_name = document.getElementById('borehole_name').value.trim();
        const depth = document.getElementById('depth').value.trim();
        const capacity = document.getElementById('capacity').value.trim();
        const hours = document.getElementById('hours').value.trim();
        const installation_year = document.getElementById('installation_year').value.trim();
        const borehole_status = document.getElementById('borehole_status').value.trim();
        const serving_areas = document.getElementById('serving_areas').value.trim();
        const zonal = selectedZonal; // Use selectedZonal state
        const ward = selectedWardId; // Use selectedWardId state
        const street = selectedStreetId; // Use selectedStreetId state
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";
        // Validate each field to ensure they are not empty
        if (
          !borehole_name ||
          !depth||
          !capacity ||
          !hours ||
          !installation_year ||
          !borehole_status ||
          !zonal ||
          !ward ||
          !street||
          !serving_areas
        ) {
          // If any field is empty, return an error
          Swal.fire({
            icon: 'error',
            title: 'Field Empty',
            text: 'Fill all the fields',
          });

          return false;
        }
    
        // Send data to server-side endpoint for updating customer details
        const response = await axios.put(`https://api.ilms.co.tz/api/update-borehole-details/${borehole_id}`, { 
            borehole_name,
          capacity,
          depth,
          installation_year,
          borehole_status,
          zonal,
          ward,
          street,
          token,
          agency_code,
          borehole_id,
          serving_areas
        });
    
        // Handle response if needed
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Borehole details updated successfully!',
        });
      } catch (error) {
        // Handle error if any
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Sorry! an error occured',
        });
      }
    };


    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <div className="modal-body col-8">
                    {noData ? (
                            <div className="text-center text-danger">No Data</div>
                        ) : (
                            <>
                        <div className="form-group">
                            <label htmlFor="borehole_name">Borehole Name</label>
                            <input type="text" className="form-control" id="borehole_name" defaultValue={WaterSourceDetails.borehole_name} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="depth">Borehole Depth</label>
                            <input type="text" className="form-control" id="depth" defaultValue={WaterSourceDetails.borehole_depth} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="capacity">Borehole Capacity</label>
                            <input type="text" className="form-control" id="capacity" defaultValue={WaterSourceDetails.borehole_capacity} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="hours">Borehole Operation Hours</label>
                            <input type="text" className="form-control" id="hours" defaultValue={WaterSourceDetails.borehole_operation_hrs} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="installation_year">Borehole Installation Year</label>
                            <input type="text" className="form-control" id="installation_year" defaultValue={WaterSourceDetails.installation_year} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="installation_year">Borehole Serving Areas</label>
                            <input type="text" className="form-control" id="serving_areas" defaultValue={WaterSourceDetails.borehole_saving_area} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="borehole_status">Borehole Status</label>
                            <select
                                id="borehole_status"
                                className="form-control"
                                value={selectedBoreholeStatus}
                                onChange={(e) => setSelectedboreholeStatus(e.target.value)}
                            >
                                {boreholeStatuses.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="zonal">Zonal</label>
                            <select
                                id="zonal"
                                className="form-control"
                                value={selectedZonal}
                                onChange={(event) => {
                                    setSelectedZonal(event.target.value);
                                    getSelectedWards(event); // Call getSelectedWards function
                                }}
                            >
                                <option value="">Select Zonal</option>
                                {zonalOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="ward">Ward</label>
                            <select
                                id="ward"
                                className="form-control"
                                value={selectedWardId}
                                onChange={(event) => {
                                    setSelectedWardId(event.target.value);
                                    fetchStreets(event); // Call fetchStreets function
                                }}
                            >
                                <option value="">Select Ward</option>
                                {wards.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="street">Street</label>
                            <select
                                id="street"
                                className="form-control"
                                value={selectedStreetId}
                                onChange={(event) => setSelectedStreetId(event.target.value)} // Update selectedStreetId state
                            >
                                <option value="">Select Streets</option>
                                {streets.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="modal-footer">
                        <button id="update_client" type="submit" onClick={updateWaterSourceDetails}  className="btn btn-md btn-success" disabled={!isAdmin}>Update Borehole Details</button>
                      </div>
                      </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBoreholes;
