import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import BoreholeLocations from "./components/boreholeLocation.js"



function BoreholeLocation() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <BoreholeLocations/>
      <Footer />
    </div>
  );
}
export default BoreholeLocation;