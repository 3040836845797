import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WardDetails from "./components/ward.js";


function Wards() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WardDetails/>
    </div>
  );
}
export default Wards;
