import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import CurrentTankLevels from "./components/CurrentTankLevel.js"



function CurrentTankLevel() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <CurrentTankLevels />
    </div>
  );
}
export default CurrentTankLevel;