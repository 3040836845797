import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import FindFaultsTechnician from "./components/FindFaultTechnician.js"


function FindFaultTechnician() {
  return (
    <div className="home">
     <Header/>
     <SideNav/>
    <FindFaultsTechnician/>
    </div>
  );
}
export default FindFaultTechnician;
