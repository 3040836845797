import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import EditBoreholes from "./components/editBorehole.js"



function EditBorehole() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <EditBoreholes/>
    </div>
  );
}
export default EditBorehole;
