import React from "react";
import SideNav from "./components/sideNavSystem.js";
import HeaderSystem from "./components/headerSystem.js"

function SystemHome() {
    return (
      <div className="home">
       <HeaderSystem/>
        <SideNav/>
       
       
      </div>
    );
  }
  export default SystemHome;