import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import AdminsFaultSummary from "./components/admisSummary.js"

function FaultSummary() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <AdminsFaultSummary/>
    </div>
  );
}
export default FaultSummary;