import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterNetworks from "./components/WaterNetwork.js"



function WaterNetwork() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
   <WaterNetworks/>
    </div>
  );
}
export default WaterNetwork;
