import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Apply classes to the root container
document.body.classList.add('hold-transition', 'sidebar-mini', 'layout-fixed');

// Function to load external scripts
function loadScript(src, integrity = '', crossorigin = '') {
  const script = document.createElement('script');
  script.src = src;
  if (integrity) script.integrity = integrity;
  if (crossorigin) script.crossOrigin = crossorigin;
  script.async = false; // Ensure scripts load in order
  document.body.appendChild(script);
}

// Load scripts previously in index.html
loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js');
loadScript('/assets/dist/js/bootstrap.bundle.min.js');
loadScript('/dist/js/adminlte.min.js');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
