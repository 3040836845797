import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function EditWaterSources() {
    const { source_id } = useParams();
    const [progress, setProgress] = useState(0);
    const [zonalOptions, setZonalOptions] = useState([]);
    const [selectedZonal, setSelectedZonal] = useState('');
    const [wards, setWards] = useState([]);
    const [selectedWardId, setSelectedWardId] = useState('');
    const [streets, setStreets] = useState([]);
    const [selectedStreetId, setSelectedStreetId] = useState('');
    const [waterSourceDetails, setWaterSourceDetails] = useState({});
    const [waterSourceStatuses, setWaterSourceStatuses] = useState([]);
    const [selectedWaterSourceStatus, setSelectedWaterSourceStatus] = useState('');
    const [noData, setNoData] = useState(false);
    const userString = localStorage.getItem("user");
    let isAdmin = false;
  
  if (userString) {
    const user = JSON.parse(userString);
    isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

    useEffect(() => {
        const fetchZonalData = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_zones', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setZonalOptions(response.data);
            } catch (error) {
                console.error('Error fetching zonal data: ', error);
            }
        };

        fetchZonalData();
    }, []);

    useEffect(() => {
        const fetchSourceStatuses = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_source_status', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setWaterSourceStatuses(response.data);
                if (response.data.length > 0) {
                    setSelectedWaterSourceStatus(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching water source statuses data: ', error);
            }
        };

        fetchSourceStatuses();
    }, []);

    const getSelectedWards = async (event) => {
        const selectedZonalId = event.target.value;
        setSelectedZonal(selectedZonalId);

        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_wards`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedZonalId: selectedZonalId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setWards(response.data);
        } catch (error) {
            console.error('Error fetching wards data: ', error);
        }
    };

    const fetchStreets = async (event) => {
        const selectedWardId = event.target.value;
        setSelectedWardId(selectedWardId);
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_streets`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedWardId: selectedWardId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setStreets(response.data);
        } catch (error) {
            console.error('Error fetching streets data: ', error);
        }
    };

    useEffect(() => {
        setProgress(50);
        const fetchSourceDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`https://api.ilms.co.tz/api/get_waterSource_details/${source_id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const sourceData = response.data[0];

                if (sourceData) {
                    setSelectedWaterSourceStatus(sourceData.status);
                    setWaterSourceDetails(sourceData);
                    setNoData(false);
                } else {
                    setNoData(true);
                }
                setProgress(100);
            } catch (error) {
                setProgress(100);
                console.error('Error fetching water source details: ', error);
            }
        };
        fetchSourceDetails();
    }, [source_id]);

    useEffect(() => {
        setSelectedZonal(waterSourceDetails.zonal);
    }, [waterSourceDetails.zonal]);

    useEffect(() => {
        setSelectedWardId(waterSourceDetails.ward);
        getSelectedWards({ target: { value: waterSourceDetails.zonal } });
    }, [waterSourceDetails.ward, waterSourceDetails.zonal]);

    useEffect(() => {
        setSelectedStreetId(waterSourceDetails.street);
        fetchStreets({ target: { value: waterSourceDetails.ward } });
    }, [waterSourceDetails.street, waterSourceDetails.ward]);

    const updateWaterSourceDetails = async () => {
        try {
            const source_name = document.getElementById('source_name').value.trim();
            const capacity = document.getElementById('capacity').value.trim();
            const saving_areas = document.getElementById('saving_areas').value.trim();
            const installation_year = document.getElementById('installation_year').value.trim();
            const waterSource_status = document.getElementById('WaterSource_status').value.trim();
            const zonal = selectedZonal;
            const ward = selectedWardId;
            const street = selectedStreetId;
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!source_name || !capacity || !saving_areas || !installation_year || !waterSource_status || !zonal || !ward || !street) {
                Swal.fire({
                    icon: 'error',
                    title: 'Field Empty',
                    text: 'Fill all the fields',
                });
                return false;
            }

            await axios.put(`https://api.ilms.co.tz/api/update-waterSource-details/${source_id}`, {
                source_name,
                capacity,
                saving_areas,
                installation_year,
                waterSource_status,
                zonal,
                ward,
                street,
                token,
                agency_code,
                source_id
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Water source details updated successfully!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Sorry! An error occurred',
            });
        }
    };

    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <div className="modal-body col-8">
                        {noData ? (
                            <div className="text-center text-danger">No Data</div>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label htmlFor="source_name">Water Source Name</label>
                                    <input type="text" className="form-control" id="source_name" defaultValue={waterSourceDetails.name} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="capacity">Uwezo wa Chanzo</label>
                                    <input type="text" className="form-control" id="capacity" defaultValue={waterSourceDetails.uwezo} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="saving_areas">Maeneo Kinapolisha</label>
                                    <input type="text" className="form-control" id="saving_areas" defaultValue={waterSourceDetails.kinapolisha} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="installation_year">Mwaka Kilipoanza Kutumika</label>
                                    <input type="text" className="form-control" id="installation_year" defaultValue={waterSourceDetails.kilipoanza_kutumika} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="WaterSource_status">Hali ya Chanzo</label>
                                    <select
                                        id="WaterSource_status"
                                        className="form-control"
                                        value={selectedWaterSourceStatus}
                                        onChange={(e) => setSelectedWaterSourceStatus(e.target.value)}
                                    >
                                        {waterSourceStatuses.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zonalSelect">Zonal</label>
                                    <select
                                        id="zonalSelect"
                                        className="form-control"
                                        value={selectedZonal}
                                        onChange={(event) => {
                                            setSelectedZonal(event.target.value);
                                            getSelectedWards(event);
                                        }}
                                    >
                                        <option value="">Select Zonal</option>
                                        {zonalOptions.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="wardSelect">Ward</label>
                                    <select
                                        id="wardSelect"
                                        className="form-control"
                                        value={selectedWardId}
                                        onChange={(event) => {
                                            setSelectedWardId(event.target.value);
                                            fetchStreets(event);
                                        }}
                                    >
                                        <option value="">Select Ward</option>
                                        {wards.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="streetSelect">Street</label>
                                    <select
                                        id="streetSelect"
                                        className="form-control"
                                        value={selectedStreetId}
                                        onChange={(event) => setSelectedStreetId(event.target.value)}
                                    >
                                        <option value="">Select Streets</option>
                                        {streets.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button id="update_client" type="submit" onClick={updateWaterSourceDetails} className="btn btn-md btn-success" disabled={!isAdmin}>Update Water Source Details</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditWaterSources;
