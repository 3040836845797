import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import Swal from 'sweetalert2';

const center = {
  lat: -6.369028,
  lng: 34.888822,
};

const containerStyle = {
  width: "100%",
  height: "600px",
};

function GisMaps({ google }) {
  const [zonals, setZonals] = useState([]);
  const [wards, setWards] = useState([]);
  const [streets, setStreets] = useState([]);
  const [totalLength, setTotalLength] = useState(null);
  const [totalLengthRest, setTotalLengthRest] = useState({});
  const [tanks, setTanks] = useState([]);
  const [boosters, setBoosters] = useState([]);
  const [fittings, setFittings] = useState([]);
  const [customers, setCustomers] = useState([]); // New state for customers
  const [selectedZonal, setSelectedZonal] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedInfrType, setSelectedInfrType] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [infoWindowContent, setInfoWindowContent] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [geojsonData, setGeojsonData] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [displayContent, setDisplayContent] = useState('All'); // Updated for tracking selected dropdown option
  const mapRef = useRef(null);

  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  useEffect(() => {
    const fetchData = async (url, setState) => {
      try {
        const response = await axios.get(url, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        setState(response.data);
      } catch (error) {
        console.error(`Error fetching ${url}:`, error);
      }
    };

    fetchData("https://api.ilms.co.tz/api/gis-zonals", setZonals);
    fetchData("https://api.ilms.co.tz/api/get-infrastructure-type", setServiceType);
  }, [token, agency_code]);

  useEffect(() => {
    if (selectedZonal) {
      setSelectedWard("");
      setSelectedStreet("");
      axios
        .get(`https://api.ilms.co.tz/api/gis-wards?zonal_id=${selectedZonal}`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => setWards(response.data))
        .catch((error) => console.error("Error fetching wards:", error));
    } else {
      setWards([]);
      setStreets([]);
    }
  }, [selectedZonal, token, agency_code]);

  useEffect(() => {
    if (selectedWard) {
      axios
        .get(`https://api.ilms.co.tz/api/streets?ward_id=${selectedWard}`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => setStreets(response.data))
        .catch((error) => console.error("Error fetching streets:", error));
    } else {
      setStreets([]);
    }
  }, [selectedWard, token, agency_code]);

  const handleSearch = async () => {
    if (!selectedZonal) {
      Swal.fire({
        icon: 'error',
        title: 'No Selected Zone',
        text: 'Please Select the Zone',
      });
      return false;
    }
  
    // Clear previous map data
    if (mapInstance) {
      mapInstance.data.forEach((feature) => mapInstance.data.remove(feature));
    }
  
    setTanks([]);
    setBoosters([]);
    setFittings([]);
    setCustomers([]); // Clear customers before new search
    setGeojsonData(null);
    setTotalLength(null);
  
    // Prepare the URLs based on the selected content
    const apiBase = 'https://api.ilms.co.tz/api';
    let urls = [
      axios.get(`${apiBase}/pipeline-length`, {
        params: {
          zonal_id: selectedZonal,
          agency_code,
          token,
          infr_type_id: selectedInfrType,
          ward_id: selectedWard,
          street_id: selectedStreet,
        },
        headers: { 'Content-Type': 'application/json' },
      }),
    ];
  
    // Add specific URLs based on the selected display content
    if (displayContent === 'All' || displayContent === 'Fittings') {
      urls.push(
        axios.get(`${apiBase}/gis-tanks`, {
          params: { zonal_id: selectedZonal, agency_code, token, ward_id: selectedWard, street_id: selectedStreet },
          headers: { 'Content-Type': 'application/json' },
        }),
        axios.get(`${apiBase}/gis-boosters`, {
          params: { zonal_id: selectedZonal, agency_code, token, ward_id: selectedWard, street_id: selectedStreet },
          headers: { 'Content-Type': 'application/json' },
        }),
        axios.get(`${apiBase}/gis-fittings`, {
          params: { zonal_id: selectedZonal, agency_code, token, ward_id: selectedWard, street_id: selectedStreet },
          headers: { 'Content-Type': 'application/json' },
        })
      );
    }
  
    if (displayContent === 'All' || displayContent === 'transmission') {
      urls.push(
        axios.get(`${apiBase}/geojson`, {
          params: { zonal_id: selectedZonal, agency_code, token, infr_type_id: selectedInfrType, ward_id: selectedWard, street_id: selectedStreet },
          headers: { 'Content-Type': 'application/json' },
        })
      );
    }
  
    if (displayContent === 'All' || displayContent === 'customers') {
      urls.push(
        axios.get(`${apiBase}/gis-customers`, {
          params: { zonal_id: selectedZonal, agency_code, token, ward_id: selectedWard, street_id: selectedStreet },
          headers: { 'Content-Type': 'application/json' },
        })
      );
    }
  
    // Fetch the data
    try {
      const responses = await Promise.all(urls);
  
      // Process each response based on the fetched data
      const totalLengthResponse = responses[0]; // Pipeline length is always the first response
      setTotalLength(totalLengthResponse.data.total_length_km);
      setTotalLengthRest(totalLengthResponse.data.infrastructure_data || {});
  
      let responseIndex = 1; // Start processing based on conditions after pipeline length
  
      if (displayContent === 'All' || displayContent === 'Fittings') {
        setTanks(responses[responseIndex++].data || []);
        setBoosters(responses[responseIndex++].data || []);
        setFittings(responses[responseIndex++].data || []);
      }
  
      if (displayContent === 'All' || displayContent === 'transmission') {
        const geojsonData = responses[responseIndex++].data;
        setGeojsonData(geojsonData);
  
        // Add GeoJSON data to the map
        if (mapInstance && geojsonData.features) {
          mapInstance.data.addGeoJson(geojsonData);
          mapInstance.data.setStyle((feature) => {
            const infrTypeId = feature.getProperty('infr_type_id');
            return {
              strokeColor: infrTypeId === 1 ? '#FF0000' : infrTypeId === 2 ? '#0000FF' : infrTypeId === 3 ? '#5BFF33' : '#000000',
              strokeWeight: 5,
            };
          });
  
          // Add listener for click events on the map features (network)
          mapInstance.data.addListener('click', (event) => {
            const feature = event.feature;
            const pipeDescription = feature.getProperty('description') || 'No description available';
            const position = event.latLng.toJSON();
            setInfoWindowContent(`<div><p>${pipeDescription}</p></div>`);
            setInfoWindowPosition(position);
            setInfoWindowVisible(true);
            setActiveMarker(null); // Close any open marker
          });
        }
      }
  
      if (displayContent === 'All' || displayContent === 'customers') {
        setCustomers(responses[responseIndex].data || []); // Store customer data
      }
    } catch (error) {
      console.error('Error fetching data:', error.response || error.message || error);
      alert('An error occurred while fetching data. Please check the console for details.');
    }
  };

  const onMarkerClick = (props, marker, e) => {
    let infoWindowContent = "";
    
    // Check if it's a fitting, tank, or customer based on the data passed
    if (props.fitting_type_name) {
      // Fitting details
      infoWindowContent = `
        <div>
          <p><strong>Fitting Type:</strong> ${props.fitting_type_name}</p>
          <p><strong>Material:</strong> ${props.fitting_material_name}</p>
          <p><strong>Size:</strong> ${props.fitting_size_name}</p>
          <p><strong>Location:</strong> ${props.location}</p>
          <p><strong>Status:</strong> ${props.fitting_status_name}</p>
          <p><strong>Installation Year:</strong> ${props.installation_year}</p>
        </div>
      `;
    } else if (props.tank_name) {
      // Tank details
      infoWindowContent = `
        <div>
          <p><strong>Tank Name:</strong> ${props.tank_name}</p>
          <p><strong>Capacity:</strong> ${props.tank_capacity} meter cubic</p>
          <p><strong>Location:</strong> ${props.location || "Not available"}</p>
        </div>
      `;
    } else if (props.first_name) {
      // Customer details
      infoWindowContent = `
        <div>
          <p><strong>Customer Name:</strong> ${props.first_name} ${props.middle_name} ${props.sur_name}</p>
          <p><strong>Phone Number:</strong> ${props.phone_number}</p>
          <p><strong>Account Number:</strong> ${props.account_number}</p>
           <p><strong>Meter Number:</strong> ${props.meter_number}</p>
          <p><strong>Location:</strong> ${props.location}</p>
        </div>
      `;
    }
  
    // Set the content and make the InfoWindow visible
    setInfoWindowContent(infoWindowContent);
    setActiveMarker(marker); // Marker is passed here
    setInfoWindowVisible(true);
    setInfoWindowPosition(marker.getPosition()); // Set position to where the marker is
  };

  const onMapClicked = () => {
    if (infoWindowVisible) {
      setInfoWindowVisible(false);
      setActiveMarker(null);
    }
  };

  const handleDisplayChange = (e) => {
    setDisplayContent(e.target.value); // Update the displayContent state
  };

  return (
    <div className="content-wrapper">
      <div className="col-12 mx-auto">
        <h4 className="mt-3 text-primary">GIS Maps</h4>
        <div className="card">
          <div className="row mb-3 p-3">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Zonal</label>
              <select className="form-control" value={selectedZonal} onChange={(e) => setSelectedZonal(e.target.value)}>
                <option value="">Select Zonal</option>
                {zonals.length > 0 ? zonals.map(zonal => <option key={zonal.id} value={zonal.id}>{zonal.name}</option>) : <option value="">No zonals available</option>}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Ward</label>
              <select className="form-control" value={selectedWard} onChange={(e) => setSelectedWard(e.target.value)} disabled={!selectedZonal}>
                <option value="">Select Ward</option>
                {wards.length > 0 ? wards.map(ward => <option key={ward.id} value={ward.id}>{ward.name}</option>) : <option value="">No wards available</option>}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Street</label>
              <select className="form-control" value={selectedStreet} onChange={(e) => setSelectedStreet(e.target.value)} disabled={!selectedWard}>
                <option value="">Select Street</option>
                {streets.length > 0 ? streets.map(street => <option key={street.id} value={street.id}>{street.name}</option>) : <option value="">No streets available</option>}
              </select>
            </div>
            
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Infrastructure Type</label>
              <select className="form-control" value={selectedInfrType} onChange={(e) => setSelectedInfrType(e.target.value)}>
                <option value="">Select Infrastructure Type</option>
                {serviceType.length > 0 ? serviceType.map(type => <option key={type.id} value={type.id}>{type.name}</option>) : <option value="">No infrastructure types available</option>}
              </select>
            </div>
         
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
            <label htmlFor="connection_status" className="mb-1 p-1">What to Display</label>
            <select id="display_content" className="form-control" value={displayContent} onChange={handleDisplayChange}>
              <option value="All">Display All</option>
              <option value="Fittings">Fittings Only</option>
              <option value="transmission">Transmission Only</option>
              <option value="customers">Customers Only</option>
            </select>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
            <button className="btn btn-primary btn-lg mt-4" onClick={handleSearch}>Search <i className="fa fa-search"></i></button>
          </div>
          </div>
          <div className="col-12 bg-light p-2">
            <div className="row">
              {/* Add fitting description here */}
            </div>
            
          <div className="row">
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'green' }}>
                    &nbsp;CONNECTED
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'blue' }}>
                    &nbsp;DISCONNECTED
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'orange' }}>
                    &nbsp;PROSPECTIVE
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'red' }}>
                    &nbsp;ILLEGAL
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row  p-2 mb-4">
        {totalLength && typeof totalLength === 'number' && (
    <div className="col card bg-white m-2">
      <p className="text-secondary text-center">Total infrastructure network in a Zone</p>
      <strong><p className="text-primary text-center">{totalLength.toFixed(3)} km</p></strong>
    </div>
  )}

          {Object.keys(totalLengthRest).length > 0 && Object.entries(totalLengthRest).map(([key, value]) => (
  <div className="col card bg-white m-2" key={key}> 
      <p className="text-secondary text-center">{key} network in a Zone</p>
      <strong><p className="text-primary text-center">{value.toFixed(3)} km</p></strong>
  </div>
))}



        </div>

        <div className="col-lg-12">
          <Map
            google={google}
            containerStyle={containerStyle}
            center={center}
            zoom={7}
            onClick={onMapClicked}
            initialCenter={center}
            mapTypeId='satellite'
            tilt={45}
            disableDefaultUI={false}
            zoomControl={true}
            mapTypeControl={true}
            scaleControl={true}
            streetViewControl={true}
            rotateControl={true}
            fullscreenControl={true}
            onReady={(mapProps, map) => setMapInstance(map)}  // Assign map to instance
          >

            {/* FITTINGS */}
            {fittings && fittings.length > 0 ? (
              fittings.map((fitting) => {
                let markerColor = "";

                // Assign the fitting icon based on the fitting type
                switch (fitting.fittings_type_id) {
                  case 1:
                    markerColor =
                      "/fittings_images/1.png";
                    break;
                  case 2:
                    markerColor =
                    "/fittings_images/2.png";
                  break;
                  case 3:
                    markerColor =
                      "/fittings_images/3.png"; 
                         break;
                  case 4:
                    markerColor =
                    "/fittings_images/4.png"; 
                    break;
                    case 26:
                    case 9:
                    case 5:
                      markerColor =
                      "/fittings_images/5.png"; 
                      break;

                      case 6:
                      markerColor =
                      "/fittings_images/6.png"; 
                      break;

                      case 7:
                      markerColor =
                      "/fittings_images/7.png"; 
                      break;
  
                      case 8:
                        markerColor =
                        "/fittings_images/8.png"; 
                        break;

                        case 11:
                        markerColor =
                        "/fittings_images/11.png"; 
                        break;

                        case 12:
                        markerColor =
                        "/fittings_images/12.png"; 
                        break;

                        case 13:
                        markerColor =
                        "/fittings_images/13.jpg"; 
                        break;

                        case 16:
                        markerColor =
                        "/fittings_images/16.png"; 
                        break;

                        case 17:
                        markerColor =
                        "/fittings_images/17.png"; 
                        break;

                        case 18:
                        markerColor =
                        "/fittings_images/18.png"; 
                        break;

                        case 19:
                        markerColor =
                        "/fittings_images/19.png"; 
                        break;

                        case 21:
                          markerColor =
                          "/fittings_images/21.png"; 
                          break;

                          case 22:
                          markerColor =
                          "/fittings_images/18.png"; 
                          break;


                          case 23:
                          markerColor =
                          "/fittings_images/23.jpg"; 
                          break;

                          case 27:
                          markerColor =
                          "/fittings_images/27.jpeg"; 
                          break;

                          case 28:
                          markerColor =
                          "/fittings_images/28.jpg"; 
                          break;
                  default:
                    markerColor = "https://maps.google.com/mapfiles/ms/icons/purple-dot.png";
                    break;
                }

                return (
                  <Marker
                    key={fitting.id}
                    position={{ lat: fitting.lat, lng: fitting.lng }}
                    title={fitting.fitting_type_name || 'No name available'}
                    onClick={(props, marker, e) => onMarkerClick(fitting, marker, e)}
                    icon={{
                      url: markerColor,
                      scaledSize: new google.maps.Size(30, 30),
                    }}
                  />
                );
              })
            ) : null}

            {/* TANKS */}
            {tanks && tanks.length > 0 && tanks.map((tank) => (
                <Marker
                  key={tank.id}
                  position={{ lat: tank.lat, lng: tank.lng }}
                  title={`Tank: ${tank.tank_name} ${tank.tank_capacity} meter cubic`} // For hover
                  onClick={(props, marker, e) => onMarkerClick(tank, marker, e)} // Call onMarkerClick with tank details
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    scaledSize: new google.maps.Size(40, 70),
                  }}
                />
              ))}

              {/* CUSTOMERS */}
              {customers && customers.length > 0 && customers.map((customer) => {
                let markerIcon = "";
                  // Determine marker color based on customer connection status and issues
                  if (customer.problem_id === 2) {
                    // If customer has a problem (problem_id = 2), set the marker to red
                    markerIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'; // Red for illegal/problematic
                  } else {
                    // Otherwise, determine marker color based on connection_status_id
                    switch (customer.connection_status_id) {
                      case 1:
                        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'; // Green for connected
                        break;
                      case 2:
                        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Blue for disconnected
                        break;
                      case 3:
                        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Orange for prospective
                        break;
                        case 4:
                        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png'; // Orange for prospective
                        break;
                      default:
                        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png'; // Purple for others
                        break;
                    }
                  }

                return (
                  <Marker
                  key={customer.id}
                  position={{ lat: customer.lat, lng: customer.lng }}
                  title={`${customer.first_name} ${customer.middle_name} ${customer.sur_name}` || 'No name available'}
                  onClick={(props, marker, e) => onMarkerClick(customer, marker, e)}
                  icon={{
                    url: markerIcon,
                    scaledSize: new google.maps.Size(30, 30),
                  }}
                />
                
                );
              })}

              <InfoWindow
                marker={activeMarker} // Associate the InfoWindow with the active marker
                visible={infoWindowVisible} // Control visibility based on state
                position={infoWindowPosition} // Ensure the correct position is used
                onClose={onMapClicked} // Close the InfoWindow when the map is clicked
              >
                <div dangerouslySetInnerHTML={{ __html: infoWindowContent }} /> {/* Render HTML content */}
              </InfoWindow>

          </Map>
         
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDDJ7KpBvgZncz4IC5YEcgmZpRdqjvd6cg"
})(GisMaps);
