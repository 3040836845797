import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function TechnicianHeader() {
  const [userName, setUserName] = useState('User');
  

  useEffect(() => {
    const userString = localStorage.getItem("user");
    console.log(userString);
    if (userString) {
      const user = JSON.parse(userString);
      setUserName(user.full_name);
    }
  }, []);

  return (
    <header>
      <nav className="navbar navbar-expand-lg border border-buttom navbar-white navbar-light">
        <div className="container-fluid">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto">
            <div className="dropdown">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Welcome, {userName}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <Link className="dropdown-item" to="/admins/technician-change-pass">
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/admins/tech-logout">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            {/* Notifications Dropdown Menu */}
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default TechnicianHeader;
