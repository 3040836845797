import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterMeasurementCategorys from "./components/waterMesurementCategory.js"


function WaterMeasurementCategory() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterMeasurementCategorys/>
     
    </div>
  );
}
export default WaterMeasurementCategory;
