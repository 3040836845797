import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import BoreholesDetails from "./components/boreholes.js"


function Boreholes() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <BoreholesDetails />
      <Footer/>
    </div>
  );
}
export default  Boreholes;
