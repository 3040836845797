import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import AppurtenanceEdits from "./components/appurtenanceEdit.js"



function AppurtenanceEdit() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
     <AppurtenanceEdits />
      <Footer/>
    </div>
  );
}
export default  AppurtenanceEdit;
