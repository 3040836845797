import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import StreetDetails from "./components/street.js";


function Streets() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <StreetDetails />
    </div>
  );
}
export default Streets;
