import React from "react";
import TechnicianHeader from "./components/technicianHeader";
import TechChangePasses  from "./components/TechChangePass";



function TechChangePass() {
  return (
    <div className="home">
      <TechnicianHeader/>
     <TechChangePasses/>
    </div>
  );
}
export default TechChangePass;
