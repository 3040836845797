import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterQualitySearches from "./components/WaterQualitySearch.js";

function WaterQualitySearch() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterQualitySearches/>
    </div>
  );
}
export default WaterQualitySearch;
