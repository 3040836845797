import React, { useState } from "react";
import axios from "axios"; // Import Axios library
import "../login.css";

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // State for loading indicator

  const setAuth = () => {
    localStorage.setItem('auth', 'true');
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const removeError = () => {
    setError("");
  };

  const handleSubmit = async () => {
    if (!validateEmail()) {
      setError("Invalid email format");
      return;
    } else if (password.trim() === "") {
      setError("Password cannot be empty");
      return;
    }
  

  
    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        "https://api.ilms.co.tz/api/login",
        { email, password }, // Send data as JSON object
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type header
          },
        }
      );
  
     
  
      setLoading(false); // Stop loading
      setError(response?.data?.message);
  
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
    
      setAuth();
      const token = response.data.token; // Assign token value
     
        localStorage.setItem("token", token); // Store token in localStorage
        setAuth();
       
      
    
      
      window.location.href = '/admins/home'; // Manually redirect to /admins/home
      
    } catch (error) {
      setLoading(false); // Stop loading
      if (error.response && error.response.status === 401) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred while submitting the form");
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="back">
        <div className="div-center card">
          <div className="content">
            <h3>Login</h3>
            <hr />
            <div className="form-group">
              <label htmlFor="userInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="userInputEmail1"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  removeError();
                }}
                onKeyUp={handleKeyUp}
              />
            </div>
            <div className="form-group">
              <label htmlFor="userInputPassword1">Password</label>
              <input
                type="password"
                className="form-control"
                id="userInputPassword1"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  removeError();
                }}
                onKeyUp={handleKeyUp}
              />
            </div>
            <div className="form-group" id="login_error">
              {error && <p className="text-danger">{error}</p>}
            </div>
            {loading ? (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow={100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: "100%" }}
                ></div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                id="submit_userlogin"
                onClick={handleSubmit}
              >
                Login
              </button>
            )}
            <hr />
            <a href="recover_pass" className="btn btn-link">
              Forget password?
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
