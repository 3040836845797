import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import TrackingTech from "./components/TrackTechnician.js"


function TrackTechinicians() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <TrackingTech />
    </div>
  );
}
export default TrackTechinicians;
