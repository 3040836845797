import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";

function MeterReplacementOverviews() {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [totalReplacements, setTotalReplacements] = useState(null);
    const [totalByReasons, setTotalByReasons] = useState([]);
    const [totalByTaken, setTotalByTaken] = useState([]);

    useEffect(() => {
        fetchTotalReplacements();
        fetchTotalbyReasons();
        fetchTotalbyTaken();
    }, []);

    const fetchTotalReplacements = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!agency_code || !token) {
                console.log("Agency code or token not found in localStorage");
                setLoading(false);
                return;
            }

            setProgress(50);

            const response = await axios.get("https://api.ilms.co.tz/api/total_replacements", {
                params: {
                    agency_code: agency_code,
                    token: token,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.data && response.data.total_replacements !== undefined) {
                setTotalReplacements(response.data.total_replacements);
            } else {
                setTotalReplacements(null);
            }

            setLoading(false);
            setProgress(100);
        } catch (error) {
            console.error("Error fetching total replacements: ", error);
            setLoading(false);
            setProgress(0);
        }
    };

    const fetchTotalbyReasons = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!agency_code || !token) {
                console.log("Agency code or token not found in localStorage");
                setLoading(false);
                return;
            }

            setProgress(50);

            const response = await axios.get("https://api.ilms.co.tz/api/total_by_reasons", {
                params: {
                    agency_code: agency_code,
                    token: token,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.data && Array.isArray(response.data.data)) {
                setTotalByReasons(response.data.data);
            } else {
                setTotalByReasons([]);
            }

            setLoading(false);
            setProgress(100);
        } catch (error) {
            console.error("Error fetching total by reasons: ", error);
            setLoading(false);
            setProgress(0);
        }
    };

    const fetchTotalbyTaken = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!agency_code || !token) {
                console.log("Agency code or token not found in localStorage");
                setLoading(false);
                return;
            }

            setProgress(50);

            const response = await axios.get("https://api.ilms.co.tz/api/total_by_taken", {
                params: {
                    agency_code: agency_code,
                    token: token,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.data && Array.isArray(response.data.data)) {
                setTotalByTaken(response.data.data);
            } else {
                setTotalByTaken([]);
            }

            setLoading(false);
            setProgress(100);
        } catch (error) {
            console.error("Error fetching total by taken: ", error);
            setLoading(false);
            setProgress(0);
        }
    };

    return (
        <div className="content-wrapper">
            <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="col-12 mx-auto">
                <div className="col-lg-12 mt-3 mb-3 p-2">
                    <h4 className="text-primary ">Meter Replacement Overview</h4>
                    {loading && <p>Loading...</p>}
                    {!loading && (
                        <>
                            <div className="col-12 bg-primary card">
                                {totalReplacements !== null ? (
                                    <div>
                                        <p className="h4 text-center pt-2 pb-2"><strong>Total Replacements: {totalReplacements}</strong></p>
                                    </div>
                                ) : (
                                    <p>No data available for total replacements.</p>
                                )}
                            </div>
                            <div>
                                <div className="col-12 ">
                                <p className="pt-4  h4">Meters Replaced by Reasons</p>
                                </div>
                                <div className="row">
                                {totalByReasons.length > 0 ? (
                                    totalByReasons.map((item, index) => (
                                        <div className="col-lg-3 col-6" key={index}>
                                        <div className="small-box bg-info">
                                            <div className="inner">
                                                <h3>{item.total_replacements}</h3>
                                                <strong>{item.reason_name}</strong>
                                            </div>
                                            <div className="icon">
                                                <i className="fa fa-users" />
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <p>No data available for total by reasons.</p>
                                )}
                            </div>
                            </div>
                            <div>
                            <div className="col-12 ">
                                <p className="pt-4  h4">Location of Replaced Meter</p>
                                </div>
                                <div className="row">
                                {totalByTaken.length > 0 ? (
                                    totalByTaken.map((item, index) => (
                                         <div className="col-lg-3 col-6" key={index}>
                                         <div className="small-box bg-success">
                                             <div className="inner">
                                                 <h3>{item.total_replacements}</h3>
                                                 <strong>{item.taken_name}</strong>
                                             </div>
                                             <div className="icon">
                                                 <i className="fa fa-users" />
                                             </div>
                                         </div>
                                     </div>
                                    ))
                                ) : (
                                    <p>No data available for total by taken.</p>
                                )}
                            </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MeterReplacementOverviews;
