import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import CustomerDetail  from "./components/customerDetails.js";


function CustomerDetails() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <CustomerDetail/>
      <Footer />
    </div>
  );
}
export default CustomerDetails;