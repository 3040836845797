import React, { useState, useEffect, useRef } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Swal from 'sweetalert2';

function WaterQualityStationMaps({ google }) {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        if (!agency_code || !token) {
          console.log("Agency code or token not found in localStorage");
          return;
        }

        setProgress(50);

        const response = await axios.get(`https://api.ilms.co.tz/api/registered_stations`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        setData(response.data);
        updateMap(response.data);
        setProgress(100);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setProgress(100);
      }
    };

    fetchStations();
  }, []);

  const updateMap = (stationData) => {
    if (!mapRef.current || !mapRef.current.map) {
      console.warn('Map reference is not initialized');
      return;
    }

    const map = mapRef.current.map;
    const bounds = new google.maps.LatLngBounds();

    stationData.forEach((station) => {
      const location = station.location.split(',');
      const latLng = new google.maps.LatLng(parseFloat(location[0]), parseFloat(location[1]));

      const contentString = `
      <div>
        <p><strong>Station Name:</strong> ${station.station_name} </p>
        <p><strong>Station Capacity:</strong> ${station.station_capacity} M3</p>
         <p><a href="https://www.google.com/maps/place/${station.location}" target="_blank"><strong>Location:</strong> ${station.location}</a></p>
        <p><strong>Station Type:</strong> ${station.station_type_name}</p>
        <p><strong>Station Working Status:</strong> ${station.station_status_name}</p>
        <p><strong>Installation Year:</strong> ${station.installation_year}</p>
      </div>
    `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      let markerIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

      const marker = new google.maps.Marker({
        position: latLng,
        map: map,
        title: `${station.station_name}`,
        icon: markerIcon,
      });

      bounds.extend(marker.getPosition());

      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
    });
    map.fitBounds(bounds);
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <p className="h3 text-primary">Registered Water Quality Stations Location</p>
          </div>
          <div className="col-lg-11 mx-auto">
            <div style={{ width: 'auto', height: '600px' }}>
              <Map
                google={google}
                zoom={6}
                initialCenter={{ lat: -6.369028, lng: 34.888822 }}
                ref={mapRef}
              >
                {/* Add any additional map components here */}
              </Map>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDDJ7KpBvgZncz4IC5YEcgmZpRdqjvd6cg'
})(WaterQualityStationMaps);
