import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import ChangeUserPass  from "./components/changePass.js";


function ChangePass() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <ChangeUserPass/>
      <Footer />
    </div>
  );
}
export default ChangePass;
