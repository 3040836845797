import React from "react";

function Footer() {
  return (
    <div>
      <center>
        <footer className="main-footer">
          <strong>Copyright © ILMS Team </strong>
          All rights reserved.
        </footer>
      </center>
    </div>
  );
}

export default Footer;
