import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Home from "./admins/home.js";
import Logout from "./admins/logout.js";
import SystemInfo from "./SystemInfo.js";
import Login from "./admins/Login.js";
import ChangePass from "./admins/changePass.js";
import logo from './images/w.gif';
import CustomerDetails from "./admins/customerDetails.js";
import EditCustomer from "./admins/editCustomers.js";
import CustomerLocation from "./admins/customerLocations.js"
import WaterTank from "./admins/waterTanks.js"
import TankWashout from "./admins/TankWashout.js"
import EditTank from "./admins/editTank.js"
import TankLocation from "./admins/tankLocation.js"
import WaterSources from "./admins/waterSources.js"
import EditWaterSource from "./admins/editWaterSource.js"
import WaterSourcesLocation from "./admins/WaterSourcesLocations.js"
import Boreholes from "./admins/boreholes.js"
import BoreholeFlash from "./admins/boreholesFlashing.js"
import EditBorehole from "./admins/editBorehole.js"
import BoreholeLocation from "./admins/boreholeLocation.js"
import WaterAppurtenance from "./admins/waterAppurtenances.js"
import AppurtenanceMap from "./admins/appurtenancesMap.js"
import AppurtenanceEdit from "./admins/appurtenanceEdit.js"
import District  from './admins/districts.js';  
import Zonal from './admins/zonal.js';
import Wards from './admins/wards.js';
import Streets  from './admins/streets.js';
import WaterQualityStation from './admins/waterQualityStations.js';
import EditQualityStation from './admins/editQualityStation.js';
import WaterQualityStationMap from './admins/watarQualityStationMap.js';
import WaterQualityStationRecord from './admins/waterQualitySatationRecords.js';
import WaterMeasurementCategory from './admins/waterMesurementCategory.js'
import WaterQualitySearch from './admins/WaterQualitySearch.js'
import WaterQualityGraphClean from './admins/WaterQualityGraphClean.js'
import WaterQualityGraphSewage from './admins/WaterGraphSewage.js'
import MeterReplacements from './admins/meterReplacement.js'
import MeterReplacementHistory from './admins/MeterReplacementHistory.js'
import FilteredMeterReplaced from './admins/filteredMeterReplaced.js'
import MeterTracking from './admins/meterTracking.js'
import MeterReplacementOverview from './admins/MeterReplacementOverview.js'
import SystemAdmins from './admins/systemAdmins.js'
import DataCollectors from './admins/dataCollectors.js'
import Techinicians from './admins/Technicians.js'
import TechnicianLogin from './admins/technicianLogin.js'
import TechnicianHomes from './admins/technicianHome.js'
import TechChangePass from './admins/techChangePass.js'
import TechLogout from './admins/techLogout.js'
import  GetLeakageTechnician from './admins/getLeakageTechnician.js'
import FaultSummary from './admins/faultSummary.js'
import GetLeakageAdmin from './admins/getLeakageAdmin.js'
import TrackTechinicians from './admins/trackTechnician.js'
import FindFaultTechnician from './admins/findFaultTechnicina.js'
import ConnectTank from './admins/connectTanks.js'
import TanksLevel from './admins/waterLevel.js'
import CurrentTankLevel from './admins/CurrentTankLevel.js'
import ViewCustomer from './admins/viewCustomer.js'
import GisMap from './admins/GisMap.js'
import ReportLeakage from './admins/reportLeakage.js'
import DataCollectorsData from './admins/DataCollectorsData.js'
import WaterNetwork from './admins/WaterNetwork.js'
import SystemLogin from './admins/SystemLogin.js'
import SystemHome from './admins/SystemHome.js'


function App() {
  useEffect(() => {
    document.title = "Integrated Leakage Management System";
    const link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = logo;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isTechnicianLoggedIn, setIsTechnicianLoggedIn] = useState(false);
  const [isSystemLoggedIn, setIsSystemLoggedIn] = useState(false);
  const [inactiveTime, setInactiveTime] = useState(0);


  // Function to handle login
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleTechnicianLogin = () => {
    setIsTechnicianLoggedIn(true);
    localStorage.setItem('auth', 'technician');
  };

  // Function to handle logout
   // Function to handle logout
   const handleLogout = () => {
    setIsLoggedIn(false);
    setIsTechnicianLoggedIn(false);
    
    // Clear the JWT token and authentication data
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    
    // Redirect to the login page using window.location.href
    window.location.href = "/admins/login";  // Full page reload and redirection
  };
  


  // Function to check if the user is authenticated
  const isAuthenticated = () => {
    const authStatus = localStorage.getItem('auth');
    console.log('Auth status:', authStatus);
    return authStatus === 'true';
  };

  const isTechnicianAuthenticated = () => {
    const techAuthStatus = localStorage.getItem('auth');
    console.log('Technician Auth status:', techAuthStatus);
    return techAuthStatus === 'technician';
  };

  const isSystemAuthenticated = () => {
    const SystemAuthStatus = localStorage.getItem('systemLogin');
    console.log('System user login:', SystemAuthStatus);
    return SystemAuthStatus === 'true';
  };
  // Function to handle logout



  useEffect(() => {
    let timeout;

    // Function to reset the inactivity timer
    const resetTimer = () => {
      clearTimeout(timeout);

      // Set a new timeout for logging out after 20 minutes (20 * 60 * 1000 milliseconds)
      timeout = setTimeout(() => {
        handleLogout();
      }, 30 * 60 * 1000); // Set to 20 minutes (or whatever you need)
    };

    // Reset the timer when there is user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    // Initialize the timer on first mount
    resetTimer();

    // Clean up event listeners and timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
    };
  }, []); // Empty dependency array ensures this effect runs once
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SystemInfo />} />
        <Route path="/admins/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/admins/technician-login" element={<TechnicianLogin  onLogin={handleTechnicianLogin}/>} />
        <Route path="/admins/system-login" element={<SystemLogin onLogin={SystemLogin} />} />

       


        {/* These are the routes for system admnis settings*/}
        <Route path="/admins/system-home" element={isSystemAuthenticated() ? <SystemHome/> : <Navigate to="/admins/system-login" replace />} />
        
        {/*System technician*/}
        <Route path="/admins/technician-home" element={isTechnicianAuthenticated() ? <TechnicianHomes/> : <Navigate to="/admins/technician-login" replace />} />
        <Route path="/admins/technician-change-pass" element={isTechnicianAuthenticated() ? <TechChangePass/> : <Navigate to="/admins/technician-login" replace />} />
        <Route path="/admins/tech-logout" element={isTechnicianAuthenticated() ? <TechLogout /> : <Navigate to="/admins/technician-login" replace />} />
        <Route path="/admins/get-leakage-technician/:zone/:leakage_id" element={isTechnicianAuthenticated() ? <GetLeakageTechnician/> : <Navigate to="/admins/technician-login" replace />} />

        {/* Protected route for the home page */}
        <Route path="/admins/home" element={isAuthenticated() ? <Home /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/logout" element={isAuthenticated() ? <Logout /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/change-password" element={isAuthenticated() ? <ChangePass /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/customer-details" element={isAuthenticated() ? <CustomerDetails /> : <Navigate to="/admins/login" replace />} />
        {/* Including the editCustomers component in JSX */}
        <Route path="/admins/edit-customer/:id" element={isAuthenticated() ? <EditCustomer /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/customer-locations" element={isAuthenticated() ? <CustomerLocation /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-tanks" element={isAuthenticated() ? <WaterTank /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/tank-washout/:tank_id/:name" element={isAuthenticated() ? <TankWashout /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/tank-edit/:tank_id" element={isAuthenticated() ? <EditTank /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/tanks-map" element={isAuthenticated() ? <TankLocation /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-sources" element={isAuthenticated() ? <WaterSources /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/tank-edit-water-source/:source_id" element={isAuthenticated() ? <EditWaterSource /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-sources-map" element={isAuthenticated() ? <WaterSourcesLocation /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-boreholes" element={isAuthenticated() ? <Boreholes /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/borehole-flash/:borehole_id/:name" element={isAuthenticated() ? <BoreholeFlash /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/borehole-edit/:borehole_id" element={isAuthenticated() ? <EditBorehole /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/boreholes-map" element={isAuthenticated() ? <BoreholeLocation /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/water-appurtenances" element={isAuthenticated() ? <WaterAppurtenance /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/appurtenances-map/:id/:name" element={isAuthenticated() ? <AppurtenanceMap /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/apputenence-edit/:id" element={isAuthenticated() ? <AppurtenanceEdit /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/districts" element={isAuthenticated() ? <District /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/zones" element={isAuthenticated() ? <Zonal /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/ward" element={isAuthenticated() ? <Wards /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/streets" element={isAuthenticated() ? <Streets /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/water-Quality-stations" element={isAuthenticated() ? <WaterQualityStation /> : <Navigate to="/admins/login" replace />} /> 
        <Route path="/admins/station-edit/:station_id" element={isAuthenticated() ? <EditQualityStation /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/stations-map" element={isAuthenticated() ? <WaterQualityStationMap /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-quality-station-records/:station_id/:station_type/:name" element={isAuthenticated() ? <WaterQualityStationRecord /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-measurement-category" element={isAuthenticated() ? <WaterMeasurementCategory /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-quality-search/:station_id/:station_type/:category/:from_date/:to_date/:name" element={isAuthenticated() ? <WaterQualitySearch /> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-clean-graphs/:station_id/:name" element={isAuthenticated() ? <WaterQualityGraphClean/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-sewage-graphs/:station_id/:name" element={isAuthenticated() ? <WaterQualityGraphSewage/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/repaced-meters" element={isAuthenticated() ? <MeterReplacements/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/meter-replacement-history/:account" element={isAuthenticated() ? <MeterReplacementHistory/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/filtered-meter-replaced/:fromDate/:toDate" element={isAuthenticated() ? <FilteredMeterReplaced/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/meters-tracking" element={isAuthenticated() ? <MeterTracking/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/meters-replacement-overview" element={isAuthenticated() ? <MeterReplacementOverview/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/system-admins" element={isAuthenticated() ? <SystemAdmins/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/data-collectors" element={isAuthenticated() ? <DataCollectors/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/technicians" element={isAuthenticated() ? <Techinicians/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/faults-summary" element={isAuthenticated() ? <FaultSummary/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/get-leakage-admins/:leakage_id" element={isAuthenticated() ? <GetLeakageAdmin/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/track-technicians" element={isAuthenticated() ? <TrackTechinicians/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/fault-find-technician/:technician_id/:fromDate/:toDate" element={isAuthenticated() ? <FindFaultTechnician/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/connect-tanks" element={isAuthenticated() ? <ConnectTank/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/tank-level" element={isAuthenticated() ? <TanksLevel/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/current-tank-level" element={isAuthenticated() ? <CurrentTankLevel/> : <Navigate to="/admins/login" replace />} />
        <Route path="admins/view-customer/:id" element={isAuthenticated() ? <ViewCustomer/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/GIS-Maps" element={isAuthenticated() ? <GisMap/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/report-leakage" element={isAuthenticated() ? <ReportLeakage/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/data-collectors-data" element={isAuthenticated() ? <DataCollectorsData/> : <Navigate to="/admins/login" replace />} />
        <Route path="/admins/water-network" element={isAuthenticated() ? <WaterNetwork/> : <Navigate to="/admins/login" replace />} />
        
       
      </Routes>
    </Router>
  );
}

export default App;
