import React, { useState } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

function MeterTrackings() {
    const [data, setData] = useState(null);
    const [replacements, setReplacements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [meterNumber, setMeterNumber] = useState('');
    const [noClientFound, setNoClientFound] = useState(false);
    const [noReplacementHistory, setNoReplacementHistory] = useState(false);
    const [searched, setSearched] = useState(false); // Track if search button is clicked

    const fetchData = async () => {
        try {
            setSearched(true);  // Mark that search button is clicked
            setLoading(true);
            setData(null);  // Reset data
            setReplacements([]);  // Reset replacements
            setNoClientFound(false);  // Reset noClientFound
            setNoReplacementHistory(false);  // Reset noReplacementHistory

            const token = localStorage.getItem("token");
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!agency_code || !token) {
                console.log("Agency code or token not found in localStorage");
                setLoading(false);
                return;
            }

            setProgress(50);

            const [dataResponse, replacementsResponse] = await Promise.all([
                axios.get("https://api.ilms.co.tz/api/get_meter_history_customers", {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        meterNumber: meterNumber,
                        limit: 30,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).catch(error => {
                    console.error("Error fetching customer data:", error);
                    return { data: { data: null } };
                }),
                axios.get("https://api.ilms.co.tz/api/get_meter_history_replacements", {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        meterNumber: meterNumber,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).catch(error => {
                    console.error("Error fetching replacement data:", error);
                    return { data: { data: [] } };
                })
            ]);

            const fetchedData = dataResponse.data.data || null;
            setData(fetchedData);
            console.log("Customer Data:", fetchedData);

            const fetchedReplacements = replacementsResponse.data.data || [];
            setReplacements(fetchedReplacements);
            console.log("Replacement Data:", fetchedReplacements);

            if (!fetchedData) {
                setNoClientFound(true);
            }

            if (fetchedReplacements.length === 0) {
                setNoReplacementHistory(true);
            }

            setLoading(false);
            setProgress(100);
        } catch (error) {
            console.error("Error fetching data: ", error);
            setLoading(false);
            setProgress(0);
            setNoClientFound(true);
            setNoReplacementHistory(true);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleDelete = (id) => {
        const token = localStorage.getItem('token');
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are about to delete this Detail. This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('https://api.ilms.co.tz/api/delete-meter-record', { data: { id: id, token: token } })
              .then(response => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The data has been deleted.',
                  icon: 'success'
                });
                // Update replacements state by removing the deleted item
                setReplacements(prevReplacements => prevReplacements.filter(item => item.id !== id));
              })
              .catch(error => {
                console.error('Error deleting customer:', error);
                Swal.fire({
                  title: 'Error!',
                  text: 'Failed to delete the customer.',
                  icon: 'error'
                });
              });
          }
        });
    };

    return (
        <div className="content-wrapper">
            <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="col-12 mx-auto">
                <div className="col-lg-12 mt-3 mb-3 p-2 card">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="h4 text-primary">Enter Meter Number to get history</p>
                        </div>
                        <div className="col-md-5">
                            <form className="form-inline my-2 my-lg-0">
                                <input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    id="Meter_no"
                                    placeholder="Enter Meter Number"
                                    value={meterNumber}
                                    onChange={(e) => setMeterNumber(e.target.value.toUpperCase())}
                                />
                                <button className="btn btn-outline-primary my-2 my-sm-0" type="button" onClick={fetchData}>
                                    Search
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {searched && noClientFound && (
                    <div className="card bg-warning">
                        <center className="p-4">No client currently uses this meter</center>
                    </div>
                )}

                {searched && data && (
                    <div className="card bg-light mt-4">
                        <div className="bg-primary text-center"><p className="p-3 h5">Meter Number {data.meter_number} History</p></div>
                        <p className="text-success pl-4 h4">Current Client</p>
                        <p className="pl-4 pt-0">
                            Name of the client: <strong className="text-success">{`${data.first_name} ${data.middle_name} ${data.sur_name}`}</strong><br/>
                            Phone Number: <strong className="text-success">{data.phone_number}</strong><br/>
                            Account Number: <strong className="text-success">{data.account_number}</strong><br/>
                            Customer Location: <strong className="text-success"><a className="text-success" href={`https://www.google.com/maps/place/${data.location}`} target="_blank" rel="noopener noreferrer">{data.location}</a></strong><br/>
                            Current Meter Number: <strong className="text-success">{data.meter_number}</strong><br/>
                        </p>
                    </div>
                )}

                {searched && !loading && meterNumber && (
                    <>
                        <div className="mb-4"></div>
                        <p className="text-center text-success h4">Meter Replacement History</p>

                        {noReplacementHistory ? (
                            <center className="text-danger">No replacement history for this meter</center>
                        ) : (
                            <div>
                                {replacements.length === 0 ? (
                                    <center className="text-danger">No replacement history for this meter</center>
                                ) : (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Installed Meter</th>
                                                <th>Replaced Meter</th>
                                                <th>Condition</th>
                                                <th>Meter Type</th>
                                                <th>Meter Brand</th>
                                                <th>Location</th>
                                                <th>Reason</th>
                                                <th>Meter Taken</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {replacements.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-success">{item.new_meter}</td>
                                                    <td className="text-danger">{item.old_meter}</td>
                                                    <td>{item.meter_condition_name}</td>
                                                    <td>{item.meter_type_name}</td>
                                                    <td>{item.meter_brand_name}</td>
                                                    <td><a className="text-primary" href={`https://www.google.com/maps/place/${item.location}`}>Click</a></td>
                                                    <td>{item.reason_name}</td>
                                                    <td>{item.meter_taken_name}</td>
                                                    <td>{formatDate(item.created_date)}</td>
                                                    <td className="text-danger"><span className="fa fa-trash" onClick={() => handleDelete(item.id)}></span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MeterTrackings;
