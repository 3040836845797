import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import MeterReplacement from "./components/meterReplacement.js";


function MeterReplacements() {
  return (
    <div className="home">
      <Header />
      <SideNav/> 
      <MeterReplacement />   
    </div>
  );
}
export default MeterReplacements;
