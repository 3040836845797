import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import MeterReplacementHistories from "./components/MeterReplacementHistory.js"


function MeterReplacementHistory() {
  return (
    <div className="home">
      <Header />
      <SideNav/> 
      <MeterReplacementHistories/>
    </div>
  );
}
export default MeterReplacementHistory;
