import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import LeakageAdmins from './components/GetLeakageAdmins.js'

function GetLeakageAdmin() {
  return (
    <div className="home">
     <Header/>
     <SideNav/>
     <LeakageAdmins/>
    </div>
  );
}
export default GetLeakageAdmin;
