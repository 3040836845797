import React from "react";
import SideNav from "./components/sideNav.js";
import Footer from "./components/footer.js";
import Header from "./components/header.js";
import WaterSourcesLocations from "./components/waterSourceLocations.js"


function WaterSourcesLocation() {
  return (
    <div className="home">
      <Header />
      <SideNav />
      <WaterSourcesLocations />
      <Footer />
    </div>
  );
}
export default WaterSourcesLocation;