import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterAppurtenance from "./components/waterAppurtenances.js"



function WaterAppurtenances() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterAppurtenance/>
    </div>
  );
}
export default WaterAppurtenances;
