import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Button, Modal } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

//this is code reuse, replace the tanks with the boreholes
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function BoreholeFlashings() {
  const [progress, setProgress] = useState(0);
  const [washoutData, setWashoutRecord] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { borehole_id, name } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  useEffect(() => {
    const fetchWashout = async () => {
      try {
        setProgress(50);
        
        
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        const response = await axios.get(`https://api.ilms.co.tz/api/get_flashing_record/${borehole_id}`, {
          params: {
            agency_code: agency_code,
            token: token,
            page: currentPage,
            borehole_id
          
          },
          headers: {
            "Content-Type": "application/json",
          }
        });

        const data = response.data;
        const washoutRecords = data.washout_records;
        
        setWashoutRecord(washoutRecords);
        setTotalPages(data.total_pages);
        setProgress(100);
      } catch (error) {
        setProgress(100);
        console.error('Error fetching washout records: ', error);
      }
    };
    fetchWashout();
  }, [currentPage]);

  const handleAddRecord = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchWashout = async () => {
    try {
      setProgress(50);
      

      const response = await axios.get(`https://api.ilms.co.tz/api/get_flashing_record/${borehole_id}`, {
        params: {
          agency_code: agency_code,
          token: token,
          borehole_id

        },
        headers: {
          "Content-Type": "application/json",
        }
      });
      
      const data = response.data;
      const washoutRecords = data.washout_records;  
      setWashoutRecord(washoutRecords);
      setProgress(100);
    } catch (error) {
      setProgress(100);
      console.error('Error fetching washout records: ', error);
    }
  };


  const addFlashingRecord = async () => {
    try {
      // Gather all the field values
      const description = document.getElementById('washout_record').value.trim();
     
      // Validate each field to ensure they are not empty
      if (!description ) {
        // If any field is empty, return an error
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Add Flashing Record',
        });
        return false;
      }
  

      const response = await axios.post(
        `https://api.ilms.co.tz/api/flashing`,
        {
          agency_code,
          token,
          borehole_id,
          description
         }, // Send data as JSON object
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type header
          },
        }
      );

      // Handle response if needed
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Flashing record added successfully!',
      }).then(() => {
        // Close the modal after success
        handleCloseModal(); // Call the function to close the modal
      });
  
      // Reload data after successful addition
      fetchWashout(); // Call the function to refetch data
  
    } catch (error) {
      // Handle error if any
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Sorry! an error occurred',
      });
    }
};

const handleDeleteData  = (washoutId) => {
  const token = localStorage.getItem('token');
  // Display confirmation dialog
  Swal.fire({
    title: 'Are you sure?',
    text: 'You are about to delete this Detail. This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      // Perform deletion operation with the customerId
      axios.delete('https://api.ilms.co.tz/api/delete-flashing', { data: { washoutId: washoutId, token:token } })
        .then(response => {
          // Handle success
          // Show success message
          Swal.fire({
            title: 'Deleted!',
            text: 'The data has been deleted.',
            icon: 'success'
          });
          // Update UI by removing the deleted customer from data state
          fetchWashout();
        })
        .catch(error => {
          // Handle error
          console.error('Error deleting customer:', error);
          // Show error message
          Swal.fire({
            title: 'Error!',
            text: 'Failed to delete the customer.',
            icon: 'error'
          });
        });
    }
  });
};
  

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-9 ">
                <p className="h3 text-primary"> {name} Flashing Record</p>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-md btn-success" onClick={handleAddRecord} disabled={!isAdmin}><span className="fa fa-plus" ></span> Add new Record</Button>
              </div>
            </div>
          </div>
          {washoutData.length === 0 ? (
            <center>
                <p className="h4">No flashing records for this borehole available</p>
            </center>
            
          ) : (
            // Loop through washout records and render each one
            washoutData.map((record, index) => (
              <div key={index}>
                 <div className="card col-lg-10 mb-2">
                  <p className="h5 p-2 border-bottom">Date: {formatDate(record.date)}</p>
                  <p className="p-2"> {record.description}</p>
                  <div className="row p-2">
                  <div className="col-md-7 float-end"></div>
                  <div className="col-md-4 float-end">
                  <Button className="float-end btn-md btn btn-danger" onClick={() => handleDeleteData(record.id)} disabled={!isAdmin}>Delete</Button>
                  </div>
                  </div>
                 </div>
                {/* Add more fields as needed */}
              </div>
            ))
          )}

          {/* Pagination */}
        <div className="d-flex justify-content-center mt-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {/* Previous page button */}
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
              </li>

              {/* Page numbers */}
              {/* Assuming totalPages is calculated elsewhere */}
              {Array.from({ length: totalPages }, (_, index) => (
                <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                  <button className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                </li>
              ))}

              {/* Next page button */}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
              </li>
            </ul>
          </nav>
          </div>


        </div>
      </div>

      {/* Modal for adding new record */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add form fields for new record here */}
        </Modal.Body>
      <div className="col-11 mx-auto">
       <div className="form-data">
        <label>Enter Flashing Description</label>
        <textarea className="form-control" id="washout_record" style={{ height: '250px' }}/>
       </div>
       </div>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={addFlashingRecord} >
            Save Record
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BoreholeFlashings;