import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import ReportLeakages from "./components/ReportLeakage.js"



function ReportLeakage() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <ReportLeakages/>
    </div>
  );
}
export default ReportLeakage;
