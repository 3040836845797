import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import DataCollectorData from "./components/DataCollectorData.js"



function DataCollectorsData() {
  return (
    <div className="home">
      <Header />
      <SideNav />
    <DataCollectorData />
    </div>
  );
}
export default DataCollectorsData;
