import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const UnclearedStatus = ({ data, onSubmit, onSuccess }) => {
  const [comment, setComment] = useState('');
  const [photo, setPhoto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const webcamRef = useRef(null);
  const [useCamera, setUseCamera] = useState(false);
  const { leakage_id } = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setUserName(user.full_name);
    }
  }, []);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 4 * 1024 * 1024) { // Check if file size exceeds 4 MB
      setError('File size should not exceed 4 MB.');
      return;
    }
    setError('');
    setPhoto(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURItoBlob(imageSrc);
    if (blob.size > 4 * 1024 * 1024) { // Check if captured photo size exceeds 4 MB
      setError('Captured photo size should not exceed 4 MB.');
      return;
    }
    setError('');
    setPhoto(blob);
    setPreviewUrl(imageSrc);
  }, [webcamRef]);

  const handleSubmitUncleared = async (e) => {
    e.preventDefault();
    if (!comment || !photo) {
      setError('Both comment and photo are required.');
      return;
    }
    setError('');
    setSubmitting(true);

    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('photo', photo);
    formData.append('user_name', userName);
    formData.append('leakage_id', leakage_id);
    formData.append('token', token);

    try {
      await onSubmit(formData);
      onSuccess(); // Call the onSuccess function passed from the parent component
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <div className="col-12">
      <p className="h5 pt-3">Start Clearing Leakage</p>
      <form onSubmit={handleSubmitUncleared}>
        <div className="mb-3">
          <label htmlFor="comment" className="form-label">Enter Comment</label>
          <textarea
            className="form-control"
            id="comment"
            rows="5"
            value={comment}
            onChange={handleCommentChange}
            disabled={submitting}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="formFileLg" className="form-label">Add Photo</label>
          <input
            className="form-control form-control-lg"
            id="formFileLg"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            disabled={submitting}
          />
        </div>
        <div className="mb-3">
          <button type="button" className="btn btn-secondary" onClick={() => setUseCamera(!useCamera)} disabled={submitting}>
            {useCamera ? 'Close Camera' : 'Open Camera'}
          </button>
          {useCamera && (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
                videoConstraints={videoConstraints}
              />
              <button type="button" className="btn btn-primary mt-2" onClick={handleCapture} disabled={submitting}>
                Capture Photo
              </button>
            </>
          )}
        </div>
        {previewUrl && (
          <div className="mb-3">
            <label className="form-label">Preview:</label>
            <img src={previewUrl} alt="Preview" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          </div>
        )}
        {error && <div className="text-danger mb-3">{error}</div>}
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default UnclearedStatus;
