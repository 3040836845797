import React, { useState } from "react";
import axios from "axios";
import "../login.css";

function TechnicianLogin({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const removeError = () => {
    setError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!validateEmail()) {
      setError("Invalid email format");
      return;
    } else if (password.trim() === "") {
      setError("Password cannot be empty");
      return;
    }

    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        "https://api.ilms.co.tz/api/technician-login", // Ensure the correct endpoint
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false); // Stop loading
      setError(response?.data?.message);

      // On success, store the token and user information in local storage
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("auth", "technician"); // Set authentication status
      
      // Call the onLogin function to update the state in the parent component
      onLogin();

      // Redirect to technician home
      window.location.href = "/admins/technician-home";
    } catch (error) {
      setLoading(false); // Stop loading
      if (error.response && error.response.status === 401) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred while submitting the form");
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <div className="back">
      <div className="div-center card">
        <div className="content">
          <h3>Login</h3>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="userInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="userInputEmail1"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  removeError();
                }}
                onKeyUp={handleKeyUp}
              />
            </div>
            <div className="form-group">
              <label htmlFor="userInputPassword1">Password</label>
              <input
                type="password"
                className="form-control"
                id="userInputPassword1"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  removeError();
                }}
                onKeyUp={handleKeyUp}
              />
            </div>
            <div className="form-group" id="login_error">
              {error && <p className="text-danger">{error}</p>}
            </div>
            {loading ? (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow={100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: "100%" }}
                ></div>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                id="submit_userlogin"
              >
                Login
              </button>
            )}
          </form>
          <hr />
          <a href="recover_pass" className="btn btn-link">
            Forget password?
          </a>
        </div>
      </div>
    </div>
  );
}

export default TechnicianLogin;
