import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import Swal from 'sweetalert2';

const center = {
  lat: -6.369028,
  lng: 34.888822,
};

const containerStyle = {
  width: "100%",
  height: "600px",
};

function WaterNetworks({ google }) {
  const [zonals, setZonals] = useState([]);
  const [wards, setWards] = useState([]);
  const [streets, setStreets] = useState([]);
  const [totalLength, setTotalLength] = useState(null);
  const [totalLengthRest, setTotalLengthRest] = useState({});
  const [tanks, setTanks] = useState([]);
  const [boosters, setBoosters] = useState([]);
  const [fittings, setFittings] = useState([]);
  const [selectedZonal, setSelectedZonal] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedInfrType, setSelectedInfrType] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [serviceType, setServiceType] = useState([]);
  const [geojsonData, setGeojsonData] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [infoWindowContent, setInfoWindowContent] = useState("");
  const mapRef = useRef(null);

  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  useEffect(() => {
    const fetchData = async (url, setState) => {
      try {
        const response = await axios.get(url, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        setState(response.data);
      } catch (error) {
        console.error(`Error fetching ${url}:`, error);
      }
    };

    fetchData("https://api.ilms.co.tz/api/gis-zonals", setZonals);
    fetchData("https://api.ilms.co.tz/api/get-infrastructure-type", setServiceType);
  }, [token, agency_code]);

  useEffect(() => {
    if (selectedZonal) {
      setSelectedWard("");
      setSelectedStreet("");
      axios
        .get(`https://api.ilms.co.tz/api/gis-wards?zonal_id=${selectedZonal}`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => setWards(response.data))
        .catch((error) => console.error("Error fetching wards:", error));
    } else {
      setWards([]);
      setStreets([]);
    }
  }, [selectedZonal, token, agency_code]);

  useEffect(() => {
    if (selectedWard) {
      axios
        .get(`https://api.ilms.co.tz/api/streets?ward_id=${selectedWard}`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => setStreets(response.data))
        .catch((error) => console.error("Error fetching streets:", error));
    } else {
      setStreets([]);
    }
  }, [selectedWard, token, agency_code]);

  const handleSearch = async () => {
    if (!selectedZonal) {
      Swal.fire({
        icon: 'error',
        title: 'No Selected Zone',
        text: 'Please Select the Zone',
      });
      return false;
     
    }

    // Clear previous map data
    if (mapInstance) {
      mapInstance.data.forEach(feature => mapInstance.data.remove(feature));
    }
    setTanks([]);
    setBoosters([]);
    setFittings([]);
    setGeojsonData(null);
    setTotalLength(null);

    try {
      const [totalLengthResponse, responseTanks, responseBoosters, responseFittings, geoJsonResponse] = await Promise.all([
        axios.get("https://api.ilms.co.tz/api/pipeline-length", {
          params: { zonal_id: selectedZonal, agency_code, token, infr_type_id: selectedInfrType, ward_id: selectedWard, street_id: selectedStreet },
          headers: { "Content-Type": "application/json" },
        }),
        axios.get("https://api.ilms.co.tz/api/gis-tanks", {
          params: { agency_code, token, zonal_id: selectedZonal, ward_id: selectedWard, street_id: selectedStreet },
          headers: { "Content-Type": "application/json" },
        }),
        axios.get("https://api.ilms.co.tz/api/gis-boosters", {
          params: { agency_code, token, zonal_id: selectedZonal, ward_id: selectedWard, street_id: selectedStreet },
          headers: { "Content-Type": "application/json" },
        }),
        axios.get("https://api.ilms.co.tz/api/gis-fittings", {
          params: { agency_code, token, zonal_id: selectedZonal, ward_id: selectedWard, street_id: selectedStreet },
          headers: { "Content-Type": "application/json" },
        }),
        axios.get("https://api.ilms.co.tz/api/geojson", {
          params: { zonal_id: selectedZonal, agency_code, token, infr_type_id: selectedInfrType, ward_id: selectedWard, street_id: selectedStreet  },
          headers: { "Content-Type": "application/json" },
        }),
      ]);

      setTotalLength(totalLengthResponse.data.total_length_km);
      setTotalLengthRest(totalLengthResponse.data.infrastructure_data || {});
      setTanks(responseTanks.data || []);
      setBoosters(responseBoosters.data || []);
      setFittings(responseFittings.data || []);
      setGeojsonData(geoJsonResponse.data);

      // Add GeoJSON data to map
      if (mapInstance && geoJsonResponse.data.features) {
        mapInstance.data.addGeoJson(geoJsonResponse.data);
        mapInstance.data.setStyle(feature => {
          const infrTypeId = feature.getProperty("infr_type_id");
          return {
            strokeColor: infrTypeId === 1 ? "#FF0000" : infrTypeId === 2 ? "#0000FF" : infrTypeId === 3 ? "#5BFF33" : "#000000",
            strokeWeight: 5,
          };
        });

        // Add listener for click events on the map features (network)
        mapInstance.data.addListener("click", event => {
          const feature = event.feature;
          const pipeDescription = feature.getProperty("description") || "No description available";
          const position = event.latLng.toJSON();
          setInfoWindowContent(`<div><p>${pipeDescription}</p></div>`);
          setInfoWindowPosition(position);
          setInfoWindowVisible(true);
          setActiveMarker(null); // Close any open marker
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error.response || error.message || error);
      alert("An error occurred while fetching data. Please check the console for details.");
    }
  };

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setInfoWindowVisible(true);
    setInfoWindowPosition(marker.getPosition());
    setInfoWindowContent(`<div><p>${props.description}</p></div>`);
  };

  const onMapClicked = () => {
    if (infoWindowVisible) {
      setInfoWindowVisible(false);
      setActiveMarker(null);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="col-12 mx-auto">
        <h4 className="mt-3 text-primary">Water Infrastructure Network</h4>
        <div className="card">
          <div className="row mb-3 p-3">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Zonal</label>
              <select className="form-control" value={selectedZonal} onChange={(e) => setSelectedZonal(e.target.value)}>
                <option value="">Select Zonal</option>
                {zonals.length > 0 ? zonals.map(zonal => <option key={zonal.id} value={zonal.id}>{zonal.name}</option>) : <option value="">No zonals available</option>}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Ward</label>
              <select className="form-control" value={selectedWard} onChange={(e) => setSelectedWard(e.target.value)} disabled={!selectedZonal}>
                <option value="">Select Ward</option>
                {wards.length > 0 ? wards.map(ward => <option key={ward.id} value={ward.id}>{ward.name}</option>) : <option value="">No wards available</option>}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Street</label>
              <select className="form-control" value={selectedStreet} onChange={(e) => setSelectedStreet(e.target.value)} disabled={!selectedWard}>
                <option value="">Select Street</option>
                {streets.length > 0 ? streets.map(street => <option key={street.id} value={street.id}>{street.name}</option>) : <option value="">No streets available</option>}
              </select>
            </div>
            
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
              <label>Infrastructure Type</label>
              <select className="form-control" value={selectedInfrType} onChange={(e) => setSelectedInfrType(e.target.value)} >
                <option value="">Select Infrastructure Type</option>
                {serviceType.length > 0 ? serviceType.map(type => <option key={type.id} value={type.id}>{type.name}</option>) : <option value="">No infrastructure types available</option>}
              </select>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-5 mb-2">
            <button className="btn btn-primary btn-lg mb-4" onClick={handleSearch}>Search <i className="fa fa-search"></i></button>
          </div>

          <div className="col-12 bg-light p-2">
            <div className="row">
              {/* Add fitting description here */}
            </div>
          </div>
        </div>

        <div className="row  p-2 mb-4">
        {totalLength && typeof totalLength === 'number' && (
    <div className="col card bg-white m-2">
      <p className="text-secondary text-center">Total infrastructure network in a Zone</p>
      <strong><p className="text-primary text-center">{totalLength.toFixed(3)} km</p></strong>
    </div>
  )}

          {Object.keys(totalLengthRest).length > 0 && Object.entries(totalLengthRest).map(([key, value]) => (
  <div className="col card bg-white m-2" key={key}> 
   
      <p className="text-secondary text-center">{key} network in a Zone</p>
       <strong><p className="text-primary text-center">{value.toFixed(3)} km</p></strong>
    
  </div>
))}



        </div>

        <div className="col-lg-12">
          <Map
            google={google}
            containerStyle={containerStyle}
            center={center}
            zoom={7}
            onClick={onMapClicked}
            initialCenter={center}
            mapTypeId='satellite'
            tilt={45}
            disableDefaultUI={false}
            zoomControl={true}
            mapTypeControl={true}
            scaleControl={true}
            streetViewControl={true}
            rotateControl={true}
            fullscreenControl={true}
            onReady={(mapProps, map) => setMapInstance(map)}  // Assign map to instance
          >

            {/* FITTINGS */}
            {fittings && fittings.length > 0 ? (
              fittings.map((fitting) => {
                let markerColor = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";  // All markers in purple

                return (
                  <Marker
                    key={fitting.id}
                    position={{ lat: fitting.lat, lng: fitting.lng }}
                    title={fitting.fittings_type_name || 'No name available'}
                    onClick={onMarkerClick}
                    icon={{
                      url: markerColor,
                      scaledSize: new google.maps.Size(30, 30),
                    }}
                    label={{
                      text: fitting.fitting_type_name,
                      color: "#000",
                      fontSize: "12px",
                      fontWeight: "bold",
                      className: "custom-label"
                    }}
                    description={
                      `<div> 
                        <p><strong>Fitting Type:</strong> ${fitting.fitting_type_name}</p>
                        <p><strong>Fitting Material:</strong> ${fitting.fitting_material_name}</p>
                        <p><strong>Fitting Size:</strong> ${fitting.fitting_size_name} M3</p>
                        <p><strong>Location:</strong> ${fitting.location}</p>
                        <p><strong>Fitting Status:</strong> ${fitting.fitting_status_name}</p>
                        <p><strong>Installation Year:</strong> ${fitting.installation_year}</p>
                        <p><strong>District Name:</strong> ${fitting.district_name}</p>
                        <p><strong>Zonal:</strong> ${fitting.zonal_name}</p>
                        <p><strong>Street:</strong> ${fitting.street_name}</p>
                        <p><strong>Ward:</strong> ${fitting.ward_name}</p>
                        <button onclick="location.href='/admins/apputenence-edit/${fitting.id}'" style="background-color: #007bff; color: #fff; padding: 5px 10px; border: none; border-radius: 3px;">Edit</button>
                      </div>`
                    }
                  />
                );
              })
            ) : null}

            {/* TANKS */}
            {tanks.map((tank) => (
              <Marker
                key={tank.id}
                position={{ lat: tank.lat, lng: tank.lng }}
                title={`Tank: ${tank.tank_name} ${tank.tank_capacity} meter cubic`}
                onClick={onMarkerClick}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  scaledSize: new google.maps.Size(40, 70),
                }}
                description={
                  `<div>
                    <p><strong>Tank Name:</strong> ${tank.tank_name}</p>
                    <p><strong>Capacity:</strong> ${tank.tank_capacity} cubic meters</p>
                    <p><strong>Location:</strong> ${tank.location}</p>
                    <p><strong>Zonal:</strong> ${tank.zonal_name}</p>
                    <p><strong>Ward:</strong> ${tank.ward_name}</p>
                  </div>`
                }
              />
            ))}

            {/* INFO WINDOWS */}
            <InfoWindow
              position={infoWindowPosition}
              visible={infoWindowVisible}
              onClose={onMapClicked}
            >
              <div dangerouslySetInnerHTML={{ __html: infoWindowContent }} />
            </InfoWindow>
          </Map>
         
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDDJ7KpBvgZncz4IC5YEcgmZpRdqjvd6cg"
})(WaterNetworks);
