import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

//note this code reuse to edit the Boreholes
//replace the tank declaration with the Boreholes
function AppurtenanceEdits() {
    const { id } = useParams();
    const [progress, setProgress] = useState(0);
    const [zonalOptions, setZonalOptions] = useState([]);
    const [selectedZonal, setSelectedZonal] = useState('');
    const [wards, setWards] = useState([]);
    const [selectedWardId, setSelectedWardId] = useState('');
    const [streets, setStreets] = useState([]);
    const [selectedStreetId, setSelectedStreetId] = useState('');
    const [WaterSourceDetails, setWaterSourceDetails] = useState({});
    const [noData, setNoData] = useState(false);
    const [apputenenceStatus, setApputenenceStatus] = useState([]);
    const [fitingMaterial, setFitingMaterial] = useState([]);
    const [fitingSize, setFitingSize] = useState([]);
    const [selectedFittingStatus, setSelectedFittingStatus] = useState('');
    const [selectedFittingMaterial, setSelectedFittingMaterial] = useState('');
    const [selectedFittingSize, setSelectedFittingSize] = useState('');
    const userString = localStorage.getItem('user');
    let isAdmin = false;
  
  if (userString) {
    const user = JSON.parse(userString);
    isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

    useEffect(() => {
        const fetchZonalData = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_zones', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setZonalOptions(response.data);
            } catch (error) {
                console.error('Error fetching zonal data: ', error);
            }
        };

        fetchZonalData();
    }, []);

    useEffect(() => {
        const fetchAppStatuses = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_appurtenance_status', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setApputenenceStatus(response.data);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching Appurtenance statuses data: ', error);
            }
        };

        fetchAppStatuses();
    }, []);

    useEffect(() => {
        const fetchFittingMaterial = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_fitting_material', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setFitingMaterial(response.data);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching Appurtenance statuses data: ', error);
            }
        };

        fetchFittingMaterial();
    }, []);

    useEffect(() => {
        const fetchFittingSize = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_fitting_size', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setFitingSize(response.data);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching Appurtenance statuses data: ', error);
            }
        };

        fetchFittingSize();
    }, []);

    const getSelectedWards = async (event) => {
        const selectedZonalId = event.target.value;
        setSelectedZonal(selectedZonalId);

        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_wards`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedZonalId: selectedZonalId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setWards(response.data);
        } catch (error) {
            console.error('Error fetching wards data: ', error);
        }
    };

    const fetchStreets = async (event) => {
        const selectedWardId = event.target.value;
        setSelectedWardId(selectedWardId);
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_streets`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedWardId: selectedWardId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setStreets(response.data);
        } catch (error) {
            console.error('Error fetching streets data: ', error);
        }
    };

    useEffect(() => {
        setProgress(50);
        const fetchboreholeDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`https://api.ilms.co.tz/api/get_appurtenance_details/${id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        id: id,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const sourceData = response.data[0];

                if (sourceData) {
                    setWaterSourceDetails(sourceData);
                    setSelectedZonal(sourceData.zone_id);
                    setSelectedFittingStatus(sourceData.fittings_status_id);
                    setSelectedFittingMaterial(sourceData.fittings_material_id);
                    setSelectedFittingSize(sourceData.fittings_size_id);
                } else {
                    setNoData(true);
                }

                setProgress(100);
            } catch (error) {
                setProgress(100);
                setNoData(true);
                console.error('Error fetching borehole details: ', error);
            }
        };
        fetchboreholeDetails();
    }, [id]);

    useEffect(() => {
        setSelectedZonal(WaterSourceDetails.zone_id);
    }, [WaterSourceDetails.zone_id]);

    useEffect(() => {
        setSelectedWardId(WaterSourceDetails.ward_id);
        getSelectedWards({ target: { value: WaterSourceDetails.zone_id } });
    }, [WaterSourceDetails.ward_id, WaterSourceDetails.zone_id]);

    useEffect(() => {
        setSelectedStreetId(WaterSourceDetails.street_id);
        fetchStreets({ target: { value: WaterSourceDetails.ward_id } });
    }, [WaterSourceDetails.street_id, WaterSourceDetails.ward_id]);

    const updateWaterSourceDetails = async () => {
        try {
            const installation_year = document.getElementById('installation_year').value.trim();
            const fitting_status = document.getElementById('fitting_status').value.trim();
            const fitting_material = document.getElementById('fitting_material').value.trim();
            const fitting_size = document.getElementById('fitting_size').value.trim();
            const zonal = selectedZonal;
            const ward = selectedWardId;
            const street = selectedStreetId;
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!fitting_status || !fitting_material || !fitting_size || !installation_year || !zonal || !ward || !street) {
                Swal.fire({
                    icon: 'error',
                    title: 'Field Empty',
                    text: 'Fill all the fields',
                });
                return false;
            }

            await axios.put(`https://api.ilms.co.tz/api/update-appurtenance-details/${id}`, {
                fitting_status,
                fitting_material,
                fitting_size,
                installation_year,
                zonal,
                ward,
                street,
                token,
                agency_code,
                id,
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Details updated successfully!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Sorry! An error occurred',
            });
        }
    };

    const handleDeleteData  = (washoutId) => {
        const token = localStorage.getItem('token');
        // Display confirmation dialog
        console.log(washoutId);
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are about to delete this Detail. This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // Perform deletion operation with the customerId
            axios.delete('https://api.ilms.co.tz/api/delete-appurtenance', { data: { washoutId: washoutId, token:token } })
              .then(response => {
                // Handle success
                // Show success message
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The data has been deleted.',
                  icon: 'success'
                });
                // Update UI by removing the deleted customer from data state
                //fetchWaterSources();
                window.location.href = '/admins/water-appurtenances';
              })
              .catch(error => {
                // Handle error
                console.error('Error deleting customer:', error);
                // Show error message
                Swal.fire({
                  title: 'Error!',
                  text: 'Failed to delete the customer.',
                  icon: 'error'
                });
              });
          }
        });
      };
    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <div className="modal-body col-8">
                        {noData ? (
                            <div className="text-center text-danger">No Data</div>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label htmlFor="installation_year">Installation Year</label>
                                    <input type="text" className="form-control" id="installation_year" defaultValue={WaterSourceDetails.installation_year} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fitting_status">Working Status</label>
                                    <select
                                        id="fitting_status"
                                        className="form-control"
                                        value={selectedFittingStatus}
                                        onChange={(e) => setSelectedFittingStatus(e.target.value)}
                                    >
                                        {apputenenceStatus.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fitting_material">Fitting Material</label>
                                    <select
                                        id="fitting_material"
                                        className="form-control"
                                        value={selectedFittingMaterial}
                                        onChange={(e) => setSelectedFittingMaterial(e.target.value)}
                                    >
                                        {fitingMaterial.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fitting_size">Fitting Size</label>
                                    <select
                                        id="fitting_size"
                                        className="form-control"
                                        value={selectedFittingSize}
                                        onChange={(e) => setSelectedFittingSize(e.target.value)}
                                    >
                                        {fitingSize.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zonal">Zonal</label>
                                    <select
                                        id="zonal"
                                        className="form-control"
                                        value={selectedZonal}
                                        onChange={(event) => {
                                            setSelectedZonal(event.target.value);
                                            getSelectedWards(event);
                                        }}
                                    >
                                        <option value="">Select Zonal</option>
                                        {zonalOptions.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ward">Ward</label>
                                    <select
                                        id="ward"
                                        className="form-control"
                                        value={selectedWardId}
                                        onChange={(event) => {
                                            setSelectedWardId(event.target.value);
                                            fetchStreets(event);
                                        }}
                                    >
                                        <option value="">Select Ward</option>
                                        {wards.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="street">Street</label>
                                    <select
                                        id="street"
                                        className="form-control"
                                        value={selectedStreetId}
                                        onChange={(event) => setSelectedStreetId(event.target.value)}
                                    >
                                        <option value="">Select Streets</option>
                                        {streets.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button id="update_client" type="submit" onClick={updateWaterSourceDetails} className="btn btn-md btn-success" disabled={!isAdmin}>Update Appurtenance Details</button>
                                    <button  className="btn btn-danger" onClick={() => handleDeleteData(id)} disabled={!isAdmin}>Delete Details</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppurtenanceEdits;
