import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import EditWaterSources from "./components/editWaterSource.js"


function EditWaterSource() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <EditWaterSources/>
    </div>
  );
}
export default EditWaterSource;
