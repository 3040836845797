import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterQualityStationRecords from "./components/WaterQualityStationRecords.js";

function WaterQualityStationRecord() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterQualityStationRecords/>
     
    </div>
  );
}
export default WaterQualityStationRecord;
