import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';

function WaterQualityGraphsClean() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const { station_id,  name } = useParams();
 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setProgress(50);
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get(`https://api.ilms.co.tz/api/clean_water_graph`, {
        params: {
          agency_code,
          token,
          station_id,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        setData(response.data.data);
        setLoading(false);
        setProgress(100);
      } else {
        setData([]);
        setLoading(false);
        setProgress(100);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      setData([]);
      setLoading(false);
      setProgress(100);
    }
  };

  const prepareChartData = (field) => {
    return {
      labels: data.map(record => new Date(record.created_at).toLocaleString()),
      datasets: [
        {
          label: field,
          data: data.map(record => record[field]),
          fill: true,
          borderColor: 'rgba(0, 123, 255, 1)',
          tension: 0.2,
        },
      ],
    };
  };

  if (loading) {
    return (
      <div>
        <LoadingBar
          progress={progress}
          color='#29D'
          onLoaderFinished={() => setProgress(0)}
        />
        <center>Loading...</center>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
        <div className="col-12 mx-auto p-0">
      <center>
        <h1 className="h4 text-primary pt-3">{name} Water Quality Graphs</h1>
      </center>
      <div className="mb-5"></div>
      <div className="chart-container col-11 mx-auto card">
        <center className="h5 text-primary pt-3">TDS Graph</center>
        <Line data={prepareChartData('tds')} />
        </div>
        <div className="mb-5"></div>
        <div className="chart-container col-11 mx-auto card">
        <center className="h5 text-primary pt-3">PH Graph</center>
        <Line data={prepareChartData('ph')} />
        </div>
        <div className="mb-5"></div>
        <div className="chart-container col-11 mx-auto card">
        <center className="h5 text-primary pt-3">Turbidity Graph</center>
        <Line data={prepareChartData('turbidity')} />
        </div>
        <div className="mb-5"></div>
        <div className="chart-container col-11 mx-auto card">
        <center className="h5 text-primary pt-3">Electrical Conductivity Graph</center>
        <Line data={prepareChartData('electrical_conductivity')} />
        </div>
        <div className="mb-5"></div>
        <div className="chart-container col-11 mx-auto card">
        <center className="h5 text-primary pt-3">Residue Chlorine Graph</center>
        <Line data={prepareChartData('residue_chlorine')} />
      </div>
    </div>
    </div>
  );
}

export default WaterQualityGraphsClean;
