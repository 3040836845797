import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import qs from 'qs';

const OnProgressStatusAdmin = ({ data, onSuccess }) => {
  const [show, setShow] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const token = localStorage.getItem("token");
  const { leakage_id } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCommentChange = (e) => setNewComment(e.target.value);

  function formatDate(dateStr) {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: false
    };
    
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }

  const fetchComments = async () => {
    if (user && token) {
      try {
        const response = await axios.get(`https://api.ilms.co.tz/api/load-leakage-comments`, {
          params: { leakage_id: leakage_id, token: token, agency_code: user.agency_code }
        });
        setComments(response.data);
      } catch (error) {
        console.error('Error loading comments:', error);
      }
    }
  };

  useEffect(() => {
    fetchComments();
  }, []); // Only run once on mount

  const handleAddComment = async () => {
    if (user && token) {
      try {
        const response = await axios.post(
          'https://api.ilms.co.tz/api/add-leakage-comment',
          qs.stringify({
            agency_code: user.agency_code,
            leakage_id: leakage_id,
            user_name: user.name,
            comment: newComment,
            token: token,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        // Add the new comment to the state and refresh comments
        setComments([...comments, response.data]);
        setNewComment('');
        handleClose();
        fetchComments(); // Reload comments after adding a new one
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="bg-info">
        <div className='row'>
          <div className='col-md-5'>
            <img src={`/uploads/${data.before_photo}`} className='img-fluid' alt="Site Image" />
          </div>
          <div className='col-md-7'>
            <p className="border-bottom h5 p-2">Site Attended by: {data.start_technician_name}</p>
            <p className='h6 pl-2'><strong>Comment:</strong></p>
            <p className='pl-2'>{data.start_comments}</p>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <h5>Comments:</h5>
        {comments.length === 0 ? (
          <p>No comments found.</p>
        ) : (
          comments.map((comment, index) => (
            <div key={index} className="mb-2">
              <div className='card bg-light p-2'>
                <div className='row'>
                  <div className='col-lg-1 col-3'>
                    <i className='fa fa-user fa-4x'></i>
                  </div>
                  <div className='col-lg-11 col-9'>
                    <p className='border-bottom'><strong>{comment.commenter_name} </strong>&nbsp;&nbsp; <i className='text-secondary'>{formatDate(comment.created_at)}</i></p>
                    <p>{comment.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <Button variant="primary" onClick={handleShow} className='ml-2'>
        Add Comment
      </Button>

      <div className="col-12">
        <p className="text-danger text-center pt-3"><i>Fault is not yet cleared</i>.</p>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newComment}
                onChange={handleCommentChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddComment}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OnProgressStatusAdmin;
