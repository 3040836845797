import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";

function SystemAdmin() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const [searchType, setSearchType] = useState("");
  
  let isAdmin = false;

  if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

  useEffect(() => {
    fetchData();
    fetchLoggedInUser();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setProgress(30);
      const response = await axios.get("https://api.ilms.co.tz/api/system-admins", {
        params: { page: currentPage, token: token, agency_code: agency_code, search: searchType }
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const fetchLoggedInUser = async () => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setLoggedInUser(user);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    if (!searchType.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Search field cannot be empty.',
      });
      return;
    }

    try {
      setProgress(30);
      const response = await axios.get("https://api.ilms.co.tz/api/search-system-admins", {
        params: { page: 1, token: token, agency_code: agency_code, search: searchType }
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
        setCurrentPage(1); // Reset to the first page of the search results
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const addNew = () => {
    setSelectedUser({ name: "", email: "" });
    setShowEditModal(true);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteData = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this user? This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete("https://api.ilms.co.tz/api/delete-system-user", {
            data: { userId: userId, token: token }
          });
          
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "The user has been deleted.",
            showConfirmButton: false,
            timer: 1500
          });
  
          fetchData(); // Refresh the data after deletion
        } catch (error) {
          console.error("Error deleting user:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete user.",
          });
        }
      }
    });
  };
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setShowResetModal(false);
    setSelectedUser(null);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEditSubmit = async () => {
    const { name, email, user_type } = selectedUser;
  
    if (!name || !email || !user_type) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
      });
      return;
    }
  
    if (!validateEmail(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please enter a valid email address.',
      });
      return;
    }
  
    try {
      if (selectedUser.id) {
        await axios.put(`https://api.ilms.co.tz/api/edit-system-user/${selectedUser.id}`, {
          name: name,
          email: email,
          user_type: user_type,
          token: token,
          agency_code: agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User details updated successfully!',
        });
      } else {
        await axios.post("https://api.ilms.co.tz/api/add-system-user", {
          name: name,
          email: email,
          user_type: user_type,
          token: token,
          agency_code: agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User added successfully!',
        });
      }
      fetchData();
      handleModalClose();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error === "User with this email already exists") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'User with this email already exists.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while saving the user.',
        });
      }
      console.error("Error saving user:", error);
    }
  };
  

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Password Mismatch',
        text: 'New password and confirm password do not match.',
      });
      return;
    }
    try {
      await axios.put(`https://api.ilms.co.tz/api/update-users-password/${selectedUser.id}`, {
        password: newPassword, token: token
      });
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Password reset successfully!',
      });
      handleModalClose();
    } catch (error) {
      console.error("Error resetting password:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to reset password.',
      });
    }
  };

  return (
    <div>
      <LoadingBar progress={progress} color="#29D" onLoaderFinished={() => setProgress(0)} />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Registered Users</p>
              </div>
              <div className="col-lg-5">
                <form className="form-inline my-2 my-lg-0" onSubmit={handleSearch}>
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    id="search_type"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                  />
                  <button className="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
                </form>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-md btn-success" onClick={addNew} disabled={!isAdmin}>
                  <span className="fa fa-plus"></span> Add new Record
                </Button>
              </div>
            </div>
          </div>
          {data.length === 0 ? (
            <center>
              <p className="h4">No users registered</p>
            </center>
          ) : (
            <div className="col-lg-10 mb-2">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <td scope="col">User Type</td>
                    <th scope="col">Reset Password</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user, index) => (
                    <tr key={index}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.user_type}</td>
                      <td>
                        <Button className="btn btn-warning" onClick={() => { setSelectedUser(user); setShowResetModal(true); }} 
                          disabled={!isAdmin}>
                          Reset Password
                        </Button>
                      </td>
                      <td>
                        <Button
                          className="btn btn-primary"
                          onClick={() => handleEdit(user)}
                          disabled={loggedInUser && loggedInUser.email === user.email || !isAdmin}
                        
                        >
                          Edit
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          className="btn btn-danger"
                          onClick={() => handleDeleteData(user.id)}
                          disabled={loggedInUser && loggedInUser.email === user.email || !isAdmin}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

     {/* Modal for adding/editing user */}
<Modal show={showEditModal} onHide={handleModalClose}>
  <Modal.Header closeButton>
    <Modal.Title>{selectedUser && selectedUser.id ? "Edit User" : "Add New User"}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formUserName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={selectedUser ? selectedUser.name : ""}
          onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
          required
        />
      </Form.Group>
      <Form.Group controlId="formUserEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={selectedUser ? selectedUser.email : ""}
          onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
          required
        />
      </Form.Group>
      <Form.Group controlId="formUserType">
        <Form.Label>User Type</Form.Label>
        <Form.Control
          as="select"
          value={selectedUser ? selectedUser.user_type : ""}
          onChange={(e) => setSelectedUser({ ...selectedUser, user_type: e.target.value })}
          required
        >
          <option value="">Select User Type</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </Form.Control>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleModalClose}>
      Close
    </Button>
    <Button variant="primary" onClick={handleEditSubmit}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal>


      {/* Modal for resetting password */}
      <Modal show={showResetModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formResetPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formResetPasswordConfirm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SystemAdmin;
