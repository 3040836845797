import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function TechnicianSummary() {
  const [data, setData] = useState([]);
  const [zonal, setZonal] = useState('');
  const [zonalName, setZonalName] = useState('');
  const [agencyCode, setAgencyCode] = useState('');
  const token = localStorage.getItem("token");

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setZonal(user.zonal_id);
      setZonalName(user.zonal_name);
      setAgencyCode(user.agency_code);
      fetchData(user.zonal_id, user.agency_code, token);

      const interval = setInterval(() => {
        fetchData(user.zonal_id, user.agency_code, token);
      }, 30000); // Fetch data every 30 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [token]);

  const fetchData = async (zonal, agencyCode, token) => {
    try {
      if (!agencyCode || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get("https://api.ilms.co.tz/api/get_reported_leakage_statistics_zone", {
        params: {
          agency_code: agencyCode,
          token: token,
          zone: zonal,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const fetchedData = response.data;
      if (fetchedData.length === 0) {
        setData([]);
      } else {
        setData(fetchedData);
      }

      console.log("Customer Data:", fetchedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "bg-danger"; // Uncleared faults
      case 1:
        return "bg-success"; // Cleared faults
      case 2:
        return "bg-warning"; // Faults in progress
      default:
        return "bg-info";
    }
  };

  return (
    <div className="col-lg-8 mx-auto col-11">
      <div className="col-lg-12 mt-3 mb-3 p-2 card">
        <p className="h4 p-2 text-primary text-center">Zone Assigned: {zonalName}</p> 
        <div className="mb-4"></div>
        <div className="row">
          {data.length === 0 ? (
            <div className="col-12 text-center">
              <p className="text-danger h4">No data found</p>
            </div>
          ) : (
            data.map((item, index) => (
              <div className="col-lg-4 col-6" key={index}>
                <div className={`small-box ${getStatusClass(item.fault_status)}`}>
                  <div className="inner">
                    <h3>{item.fault_count}</h3>
                    <strong>{item.description}</strong>
                  </div>
                  <div className="icon">
                    <i className="fa fa-exclamation-circle" />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default TechnicianSummary;
