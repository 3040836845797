import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function EditTanks() {
    const { tank_id } = useParams();
    const [progress, setProgress] = useState(0);
    const [zonalOptions, setZonalOptions] = useState([]);
    const [selectedZonal, setSelectedZonal] = useState('');
    const [wards, setWards] = useState([]);
    const [selectedWardId, setSelectedWardId] = useState('');
    const [streets, setStreets] = useState([]);
    const [selectedStreetId, setSelectedStreetId] = useState('');
    const [tankDetails, setTankDetails] = useState({});
    const [tankTypes, setTankTypes] = useState([]);
    const [selectedTankType, setSelectedTankType] = useState('');
    const [tankStatuses, setTankStatuses] = useState([]);
    const [selectedTankStatus, setSelectedTankStatus] = useState('');
    const [noData, setNoData] = useState(false);
    const userString = localStorage.getItem('user');
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

    useEffect(() => {
        const fetchZonalData = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_zones', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setZonalOptions(response.data);
            } catch (error) {
                console.error('Error fetching zonal data: ', error);
            }
        };

        fetchZonalData();
    }, []);

    useEffect(() => {
        const fetchTankTypes = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_tank_type', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setTankTypes(response.data);
                if (response.data.length > 0) {
                    setSelectedTankType(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching tank types data: ', error);
            }
        };

        fetchTankTypes();
    }, []);

    useEffect(() => {
        const fetchTankStatuses = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('https://api.ilms.co.tz/api/get_tank_status', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setTankStatuses(response.data);
                if (response.data.length > 0) {
                    setSelectedTankStatus(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching tank statuses data: ', error);
            }
        };

        fetchTankStatuses();
    }, []);

    const getSelectedWards = async (event) => {
        const selectedZonalId = event.target.value;
        setSelectedZonal(selectedZonalId);

        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_wards`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedZonalId: selectedZonalId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setWards(response.data);
        } catch (error) {
            console.error('Error fetching wards data: ', error);
        }
    };

    const fetchStreets = async (event) => {
        const selectedWardId = event.target.value;
        setSelectedWardId(selectedWardId);
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`https://api.ilms.co.tz/api/get_streets`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedWardId: selectedWardId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setStreets(response.data);
        } catch (error) {
            console.error('Error fetching streets data: ', error);
        }
    };

    useEffect(() => {
        setProgress(50);
        const fetchTankDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`https://api.ilms.co.tz/api/get_tank_details/${tank_id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const tankData = response.data[0];           

                if (tankData) {
                    setSelectedTankType(tankData.tank_type_id);
                    setSelectedTankStatus(tankData.tank_status_id);
                    setTankDetails(tankData);
                    setNoData(false);
                } else {
                    setNoData(true);
                }
                setProgress(100);
            } catch (error) {
                setNoData(true);
                setProgress(100);
                console.error('Error fetching tank details: ', error);
            }
        };
        fetchTankDetails();
    }, [tank_id]);

    useEffect(() => {
        setSelectedZonal(tankDetails.zone_id);
    }, [tankDetails.zone_id]);

    useEffect(() => {
        setSelectedWardId(tankDetails.ward_id);
        getSelectedWards({ target: { value: tankDetails.zone_id } });
    }, [tankDetails.ward_id, tankDetails.zone_id]);

    useEffect(() => {
        setSelectedStreetId(tankDetails.street_id);
        fetchStreets({ target: { value: tankDetails.ward_id } });
    }, [tankDetails.street_id, tankDetails.ward_id]);

    const updateTankDetails = async () => {
        try {
            const tank_name = document.getElementById('tank_name').value.trim();
            const tank_capacity = document.getElementById('tank_capacity').value.trim();
            const tank_saving_areas = document.getElementById('tank_saving_areas').value.trim();
            const installation_year = document.getElementById('installation_year').value.trim();
            const tank_type = selectedTankType;
            const tank_status = selectedTankStatus;
            const zonal = selectedZonal;
            const ward = selectedWardId;
            const street = selectedStreetId;
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
            const tank_id = tankDetails.id;

            if (
                !tank_name ||
                !tank_capacity ||
                !tank_saving_areas ||
                !installation_year ||
                !tank_type ||
                !tank_status ||
                !zonal ||
                !ward ||
                !street
            ) {
                Swal.fire({
                    icon: 'error',
                    title: 'Field Empty',
                    text: 'Fill all the fields',
                });

                return false;
            }

            const response = await axios.put(`https://api.ilms.co.tz/api/update-tank-details/${tank_id}`, {
                tank_name,
                tank_capacity,
                tank_type,
                tank_saving_areas,
                installation_year,
                tank_status,
                zonal,
                ward,
                street,
                token,
                agency_code,
                tank_id
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Tank details updated successfully!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Sorry! an error occured',
            });
        }
    };

    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <div className="modal-body col-8">
                        {noData ? (
                            <div className="text-center text-danger">No Data</div>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label htmlFor="tank_name">Tank Name</label>
                                    <input type="text" className="form-control" id="tank_name" defaultValue={tankDetails.tank_name} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tank_capacity">Uwezo wa Tank</label>
                                    <input type="text" className="form-control" id="tank_capacity" defaultValue={tankDetails.tank_capacity} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tank_saving_areas">Maeneo linapohudumia</label>
                                    <input type="text" className="form-control" id="tank_saving_areas" defaultValue={tankDetails.tank_saving_areas} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="installation_year">Mwaka Lilipojengwa</label>
                                    <input type="text" className="form-control" id="installation_year" defaultValue={tankDetails.installation_year} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tank_type">Tank Type</label>
                                    <select
                                        id="tank_type"
                                        className="form-control"
                                        value={selectedTankType}
                                        onChange={(e) => setSelectedTankType(e.target.value)}
                                    >
                                        {tankTypes.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tank_status">Tank Status</label>
                                    <select
                                        id="tank_status"
                                        className="form-control"
                                        value={selectedTankStatus}
                                        onChange={(e) => setSelectedTankStatus(e.target.value)}
                                    >
                                        {tankStatuses.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zonalSelect">Zonal</label>
                                    <select
                                        id="zonalSelect"
                                        className="form-control"
                                        value={selectedZonal}
                                        onChange={(event) => {
                                            setSelectedZonal(event.target.value);
                                            getSelectedWards(event);
                                        }}
                                    >
                                        <option value="">Select Zonal</option>
                                        {zonalOptions.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="wardSelect">Ward</label>
                                    <select
                                        id="wardSelect"
                                        className="form-control"
                                        value={selectedWardId}
                                        onChange={(event) => {
                                            setSelectedWardId(event.target.value);
                                            fetchStreets(event);
                                        }}
                                    >
                                        <option value="">Select Ward</option>
                                        {wards.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="streetSelect">Street</label>
                                    <select
                                        id="streetSelect"
                                        className="form-control"
                                        value={selectedStreetId}
                                        onChange={(event) => setSelectedStreetId(event.target.value)}
                                    >
                                        <option value="">Select Streets</option>
                                        {streets.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="modal-footer">
                                    <button id="update_client" type="submit" onClick={updateTankDetails} className="btn btn-md btn-success"  disabled={!isAdmin}>Update Tank Details</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditTanks;
