import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import EditWaterQualityStation from "./components/editQualityStation.js"


function EditQualityStation() {
  return (
    <div className="home">
    <Header />
    <SideNav/>
   <EditWaterQualityStation/>
  </div>
  );
}
export default EditQualityStation;
