import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import Districts from "./components/districts.js";

function District() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <Districts />
    </div>
  );
}
export default District;
