import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import GisMaps from "./components/GisMap.js"


function GisMap() {
  return (
    <div>
      <Header />
      <SideNav/>
      <GisMaps />
    </div>
  );
}
export default GisMap;
