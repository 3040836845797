import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import EditCustomers from "./components/editCustomer.js";


function EditCustomer() {
  return (
    <div className="home">
    <Header />
    <SideNav/>
    <EditCustomers/>
  </div>
  );
}
export default EditCustomer;
