import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import WaterTanks from "./components/WaterTanks.js"
import Footer from "./components/footer.js";


function WaterTank() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <WaterTanks/>
      <Footer/>
    </div>
  );
}
export default WaterTank;
