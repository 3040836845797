import React from "react";
import SideNav from "./components/sideNav.js";
import Header from "./components/header.js";
import EditTanks from "./components/editTank.js"


function EditTank() {
  return (
    <div className="home">
      <Header />
      <SideNav/>
      <EditTanks/>
    </div>
  );
}
export default EditTank;
