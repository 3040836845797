import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from 'react-router-dom';

function FindFaultsTechnician() {
  const token = localStorage.getItem("token");
  const { technician_id, fromDate, toDate } = useParams();
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  const [technicianData, setTechnicianData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchTechnicianData = async () => {
      try {
        setLoading(true);
        setProgress(50);
        const [technicianResponse, leakageResponse] = await Promise.all([
          axios.get('https://api.ilms.co.tz/api/faults-technician-details', {
            params: {
              agency_code: agency_code,
              token: token,
              technician_id: technician_id,
              fromDate: fromDate,
              toDate: toDate,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }),
          axios.get("https://api.ilms.co.tz/api/get-technician-cleared-faults", {
            params: {
              agency_code: agency_code,
              page: currentPage,
              token: token,
              technician_id: technician_id,
              fromDate: fromDate,
              toDate: toDate,
              limit: 30,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }),
        ]);

        setTechnicianData(technicianResponse.data.data);
        const fetchedData = leakageResponse.data.faults || [];
        setData(fetchedData);
        const totalCount = leakageResponse.data.totalPages || 1;
        setTotalPages(totalCount);

        setLoading(false);
        setProgress(100);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
        setProgress(0);
      }
    };

    fetchTechnicianData();
  }, [agency_code, token, technician_id, fromDate, toDate, currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Uncleared faults':
        return 'text-danger';
      case 'Cleared faults':
        return 'text-success';
      case 'Faults in progress':
        return 'text-warning';
      default:
        return '';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="content-wrapper">
      <div className="col-lg-11 col-11">
        <center>
          <p className="text-primary h4">Date selected: {formatDate(fromDate)} to {formatDate(toDate)}</p>
        </center>
        {technicianData ? (
          <div className="card col-11">
            <div className="card-header">
              <p className="h4">Technician Name: {technicianData.full_name}</p>
            </div>
            <div className="card-body">
              <p>Email: {technicianData.email}</p>
              <p>Total Faults Cleared: <strong className="text-danger">{technicianData.cleared_faults}</strong></p>
            </div>
          </div>
        ) : (
          <div>No data found</div>
        )}
        <div className="col-lg-11 mt-3 mb-3 p-2 card">
          <div className="mb-4"></div>
          {loading ? (
            <center>Loading...</center>
          ) : (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Reported Date</th>
                    <th>Fault Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    <tr>
                      <td colSpan="10" className="text-center text-danger h4">No data found</td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.created_at)}</td>
                        <td>{item.service_type_name}</td>
                        <td className="text-success">Cleared</td>
                        <td className="text-primary"><Link to={`/admins/get-leakage-admins/${item.id}`} className="btn btn-md btn-primary">View</Link></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav className="d-flex justify-content-center mt-3">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FindFaultsTechnician;
